import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { DirectivesModule } from '@freelancer/directives';
import { FreelancerAsyncPipeModule, PipesModule } from '@freelancer/pipes';
import { AcceptRateComponent } from './accept-rate/accept-rate.component';
import { AnchorScrollModule } from './anchor-scroll/anchor-scroll.module';
import { AnimatedSvgModule } from './animated-svg/animated-svg.module';
import { AnnotationIndicatorComponent } from './annotation-indicator/annotation-indicator.component';
import { BadgeComponent } from './badge/badge.component';
import { BannerAlertComponent } from './banner-alert/banner-alert.component';
import {
  BannerAnnouncementButtonsComponent,
  BannerAnnouncementComponent,
  BannerAnnouncementMessageComponent,
} from './banner-announcement/banner-announcement.component';
import {
  BannerUpsellBackgroundImageComponent,
  BannerUpsellButtonComponent,
  BannerUpsellComponent,
  BannerUpsellDescriptionComponent,
  BannerUpsellDisclaimerComponent,
  BannerUpsellPictureComponent,
  BannerUpsellSmallTitleComponent,
  BannerUpsellTitleComponent,
} from './banner-upsell/banner-upsell.component';
import {
  BestPractisesComponent,
  DoBestPractisesComponent,
  DontBestPractisesComponent,
} from './best-practises/best-practises.component';
import { BitComponent } from './bit/bit.component';
import {
  BreadcrumbsComponent,
  BreadcrumbsItemComponent,
} from './breadcrumbs/breadcrumbs.component';
import { BudgetComponent } from './budget.component';
import { ButtonComponent } from './button/button.component';
import { CalloutContentComponent } from './callout/callout-content.component';
import { CalloutTriggerComponent } from './callout/callout-trigger.component';
import { CalloutComponent } from './callout/callout.component';
import { CameraInputComponent } from './camera-input/camera-input.component';
import { CameraComponent } from './camera/camera.component';
import {
  CardListComponent,
  CardListItemComponent,
} from './card-list/card-list.component';
import {
  CardComponent,
  CardHeaderRightComponent,
  CardHeaderSecondaryComponent,
  CardHeaderTitleComponent,
} from './card/card.component';
import {
  CarouselComponent,
  CarouselItemComponent,
  CarouselNextButtonComponent,
  CarouselPrevButtonComponent,
} from './carousel/carousel.component';
import { ChartBarComponent } from './chart/chart-bar.component';
import { ChartComponent } from './chart/chart.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ColumnComponent } from './column/column.component';
import { CompletedJobsComponent } from './completed-jobs/completed-jobs.component';
import { ContainerComponent } from './container/container.component';
import {
  DateFnsDateAdapter,
  MAT_DATEFNS_DATE_FORMATS,
} from './date-fns-date-adapter.service';
import { DirectoryItemComponent } from './directory-item/directory-item.component';
import {
  DisplayCardComponent,
  DisplayCardContentComponent,
  DisplayCardFooterLeftComponent,
  DisplayCardFooterRightComponent,
  DisplayCardHeaderLeftComponent,
  DisplayCardHeaderRightComponent,
  DisplayCardImageComponent,
} from './display-card/display-card.component';
import { DrawerComponent } from './drawer/drawer.component';
import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component';
import { EarningsComponent } from './earnings/earnings.component';
import { FileDisplayComponent } from './file-display/file-display.component';
import { FileSelectComponent } from './file-select/file-select.component';
import { FlagComponent } from './flag/flag.component';
import { FloatingActionComponent } from './floating-action/floating-action.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { GridComponent } from './grid/grid.component';
import { HeadingComponent } from './heading/heading.component';
import { HighlightTextComponent } from './highlight-text/highlight-text.component';
import { HighlightCalloutComponent } from './highlight/highlight-callout.component';
import { HighlightComponent } from './highlight/highlight.component';
import { HrComponent } from './hr/hr.component';
import { IconModule } from './icon/icon.module';
import { ImageCaptionComponent } from './image-caption/image-caption.component';
import { InfiniteScrollContainerDirective } from './infinite-scroll/infinite-scroll-container.directive';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { InputComponent } from './input/input.component';
import { NumberValueAccessorDirective } from './input/value-accessors/number-value-accessor.directive';
import { LabelComponent } from './label/label.component';
import { LinkModule } from './link/link.module';
import {
  ListItemBodyComponent,
  ListItemComponent,
  ListItemHeaderComponent,
} from './list-item/list-item.component';
import { ListComponent } from './list/list.component';
import { LoadingTextComponent } from './loading-text/loading-text.component';
import { LocalizedDateFns } from './localized-date-fns.service';
import { LocationInputComponent } from './location-input/location-input.component';
import { LogoComponent } from './logo/logo.component';
import { MapComponent } from './map/map.component';
import { MarginModule } from './margin/margin.module';
import type { LightGalleryConfig } from './media-viewer/media-viewer-light-gallery';
import { LIGHTGALLERY_CONFIG } from './media-viewer/media-viewer-light-gallery';
import { ModalTitleDirective } from './modal/modal-title.directive';
import {
  MoreOptionsComponent,
  MoreOptionsItemComponent,
} from './more-options/more-options.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { MultipleLocationInputComponent } from './multiple-location-input/multiple-location-input.component';
import { NewFeatureTagComponent } from './new-feature-tag';
import { NotificationIndicatorComponent } from './notification-indicator/notification-indicator.component';
import { OnlineIndicatorComponent } from './online-indicator/online-indicator.component';
import {
  PageLayoutComponent,
  PageLayoutPrimaryComponent,
  PageLayoutSecondaryComponent,
  PageLayoutSingleComponent,
} from './page-layout/page-layout.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PictureComponent } from './picture/picture.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import {
  ProgressStepCalloutComponent,
  ProgressStepContentComponent,
  ProgressStepItemComponent,
} from './progress-steps/progress-step-item.component';
import { ProgressStepsComponent } from './progress-steps/progress-steps.component';
import { ProgressSubstepComponent } from './progress-steps/progress-substep.component';
import { RadioComponent } from './radio/radio.component';
import { RatingComponent } from './rating/rating.component';
import { RelativeTimeComponent } from './relative-time/relative-time.component';
import { ReviewCountComponent } from './review-count/review-count.component';
import { RibbonComponent } from './ribbon/ribbon.component';
import { ScrollableContentComponent } from './scrollable-content/scrollable-content.component';
import { SearchComponent } from './search/search.component';
import {
  SectionArticleButtonComponent,
  SectionArticleComponent,
  SectionArticleDescriptionComponent,
  SectionArticleEntryComponent,
  SectionArticleEntryDetailComponent,
  SectionArticleEntryHeadingComponent,
  SectionArticleTitleComponent,
} from './section-article/section-article.component';
import {
  SectionCaseStudyCarouselComponent,
  SectionCaseStudyCarouselFooterComponent,
  SectionCaseStudyCarouselFooterHeaderComponent,
  SectionCaseStudyCarouselFooterSubHeaderComponent,
  SectionCaseStudyCarouselHeaderComponent,
  SectionCaseStudyCarouselHeaderDetailComponent,
  SectionCaseStudyCarouselItemComponent,
  SectionCaseStudyCarouselSubHeaderComponent,
} from './section-case-study-carousel/section-case-study-carousel.component';
import {
  SectionCompaniesComponent,
  SectionCompaniesTitleComponent,
} from './section-companies/section-companies.component';
import {
  SectionFeatureBodyComponent,
  SectionFeatureButtonComponent,
  SectionFeatureButtonDetailComponent,
  SectionFeatureComponent,
  SectionFeatureDetailComponent,
  SectionFeatureHeadingComponent,
  SectionFeatureSubheadingComponent,
} from './section-feature/section-feature.component';
import {
  SectionGalleryComponent,
  SectionGalleryItemComponent,
} from './section-gallery/section-gallery.component';
import {
  SectionHeroBackgroundImageComponent,
  SectionHeroComponent,
  SectionHeroCustomContentComponent,
  SectionHeroDescriptionComponent,
  SectionHeroDetailTextComponent,
  SectionHeroImageDetailComponent,
  SectionHeroLogoComponent,
  SectionHeroTitleComponent,
  SectionHeroVideoBackgroundComponent,
} from './section-hero/section-hero.component';
import {
  SectionHomepageEntryComponent,
  SectionHomepageEntryDescriptionComponent,
  SectionHomepageEntryHeadingComponent,
  SectionHomepageEntryLinkComponent,
} from './section-homepage-feature/section-homepage-entry.component';
import {
  SectionHomepageFeatureBottomLeftComponent,
  SectionHomepageFeatureBottomRightComponent,
  SectionHomepageFeatureComponent,
  SectionHomepageFeatureFooterComponent,
  SectionHomepageFeatureTitleComponent,
  SectionHomepageFeatureTopLeftComponent,
  SectionHomepageFeatureTopRightComponent,
} from './section-homepage-feature/section-homepage-feature.component';
import { SelectComponent } from './select/select.component';
import { ShareButtonsComponent } from './share-buttons/share-buttons.component';
import { ShareLinkComponent } from './share-link/share-link.component';
import { SidebarFooterComponent } from './sidebar/sidebar-footer.component';
import { SidebarHeadingDirective } from './sidebar/sidebar-heading.directive';
import { SidebarItemComponent } from './sidebar/sidebar-item.component';
import { SidebarSubheadingDirective } from './sidebar/sidebar-subheading.directive';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarCustomTemplateDirective } from './sidebar/sidebar.directive';
import { SliderComponent } from './slider/slider.component';
import { SocialButtonsArrowComponent } from './social-buttons/social-buttons-arrow.component';
import { SocialButtonsComponent } from './social-buttons/social-buttons.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SplitButtonOptionComponent } from './split-button/split-button-option.component';
import { SplitButtonPrimaryComponent } from './split-button/split-button-primary.component';
import { SplitButtonComponent } from './split-button/split-button.component';
import { StickyFooterBodyComponent } from './sticky-footer/sticky-footer-body.component';
import { StickyFooterWrapperComponent } from './sticky-footer/sticky-footer-wrapper.component';
import { StickyFooterComponent } from './sticky-footer/sticky-footer.component';
import { StickyModule } from './sticky/sticky.module';
import { StoryHeaderComponent } from './story-header/fl-story-header.component';
import {
  TableColumnComponent,
  TableComponent,
  TableExpandableContentComponent,
} from './table/table.component';
import {
  TabItemActionComponent,
  TabItemComponent,
} from './tabs/tab-item.component';
import { TabsComponent } from './tabs/tabs.component';
import { TagStatusComponent } from './tag-status/tag-status.component';
import { TagComponent } from './tag/tag.component';
import { TextComponent } from './text/text.component';
import { TextareaComponent } from './textarea/textarea.component';
import {
  ThumbnailViewerComponent,
  ThumbnailViewerImageComponent,
} from './thumbnail-viewer/thumbnail-viewer.component';
import {
  TitleCardComponent,
  TitleCardStatusComponent,
  TitleCardTabNameComponent,
} from './title-card/title-card.component';
import { ToastAlertContainerComponent } from './toast-alert/toast-alert-container.component';
import { ToastAlertGlobalComponent } from './toast-alert/toast-alert-global.component';
import { ToastAlertComponent } from './toast-alert/toast-alert.component';
import { ToggleButtonsComponent } from './toggle-buttons/toggle-buttons.component';
import { ToggleComponent } from './toggle/toggle.component';
import { UI_CONFIG } from './ui.config';
import type { UiConfig } from './ui.interface';
import { UnreadIndicatorComponent } from './unread-indicator/unread-indicator.component';
import { UpgradeTagComponent } from './upgrade-tag/upgrade-tag.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import {
  UserCardCarouselItemButtonComponent,
  UserCardCarouselItemComponent,
  UserCardCarouselItemDescriptionComponent,
} from './user-card-carousel/user-card-carousel-item.component';
import { UserCardCarouselComponent } from './user-card-carousel/user-card-carousel.component';
import { UsernameComponent } from './username/username.component';
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { VideoRecordInputComponent } from './video-record-input/video-record-input.component';
import { VideoModule } from './video/video.module';
import { ViewHeaderComponent } from './view-header/view-header.component';
import {
  WidgetButtonComponent,
  WidgetComponent,
} from './widget/widget.component';
import { WorkedTimeComponent } from './worked-time/worked-time.component';

@NgModule({
  declarations: [
    AcceptRateComponent,
    AnnotationIndicatorComponent,
    BadgeComponent,
    BannerAlertComponent,
    BannerAnnouncementButtonsComponent,
    BannerAnnouncementComponent,
    BannerAnnouncementMessageComponent,
    BannerUpsellBackgroundImageComponent,
    BannerUpsellButtonComponent,
    BannerUpsellComponent,
    BannerUpsellDescriptionComponent,
    BannerUpsellDisclaimerComponent,
    BannerUpsellPictureComponent,
    BannerUpsellSmallTitleComponent,
    BannerUpsellTitleComponent,
    BestPractisesComponent,
    BitComponent,
    BreadcrumbsComponent,
    BreadcrumbsItemComponent,
    BudgetComponent,
    ButtonComponent,
    CalloutComponent,
    CalloutContentComponent,
    CalloutTriggerComponent,
    CameraComponent,
    CameraInputComponent,
    CardComponent,
    CardHeaderRightComponent,
    CardHeaderSecondaryComponent,
    CardHeaderTitleComponent,
    CardListComponent,
    CardListItemComponent,
    CarouselComponent,
    CarouselItemComponent,
    CarouselNextButtonComponent,
    CarouselPrevButtonComponent,
    ChartBarComponent,
    ChartComponent,
    CheckboxComponent,
    ColumnComponent,
    CompletedJobsComponent,
    ContainerComponent,
    DirectoryItemComponent,
    DisplayCardComponent,
    DisplayCardContentComponent,
    DisplayCardFooterLeftComponent,
    DisplayCardFooterRightComponent,
    DisplayCardHeaderLeftComponent,
    DisplayCardHeaderRightComponent,
    DisplayCardImageComponent,
    DoBestPractisesComponent,
    DontBestPractisesComponent,
    DrawerComponent,
    DropdownFilterComponent,
    EarningsComponent,
    FileDisplayComponent,
    FileSelectComponent,
    FlagComponent,
    FloatingActionComponent,
    GenericErrorComponent,
    GridComponent,
    HeadingComponent,
    HighlightCalloutComponent,
    HighlightComponent,
    HighlightTextComponent,
    HrComponent,
    ImageCaptionComponent,
    InfiniteScrollComponent,
    InfiniteScrollContainerDirective,
    InputComponent,
    LabelComponent,
    ListComponent,
    ListItemBodyComponent,
    ListItemComponent,
    ListItemComponent,
    ListItemHeaderComponent,
    LoadingTextComponent,
    LocationInputComponent,
    LogoComponent,
    MapComponent,
    ModalTitleDirective,
    MoreOptionsComponent,
    MoreOptionsItemComponent,
    MultipleLocationInputComponent,
    MultiSelectComponent,
    NewFeatureTagComponent,
    NotificationIndicatorComponent,
    NumberValueAccessorDirective,
    OnlineIndicatorComponent,
    PageLayoutComponent,
    PageLayoutPrimaryComponent,
    PageLayoutSecondaryComponent,
    PageLayoutSingleComponent,
    PaginationComponent,
    PictureComponent,
    ProgressBarComponent,
    ProgressStepCalloutComponent,
    ProgressStepContentComponent,
    ProgressStepItemComponent,
    ProgressStepsComponent,
    ProgressSubstepComponent,
    RadioComponent,
    RatingComponent,
    RelativeTimeComponent,
    ReviewCountComponent,
    RibbonComponent,
    ScrollableContentComponent,
    SearchComponent,
    ShareButtonsComponent,
    ShareLinkComponent,
    SectionArticleButtonComponent,
    SectionArticleComponent,
    SectionArticleDescriptionComponent,
    SectionArticleEntryComponent,
    SectionArticleEntryDetailComponent,
    SectionArticleEntryHeadingComponent,
    SectionArticleTitleComponent,
    SectionCaseStudyCarouselComponent,
    SectionCaseStudyCarouselFooterComponent,
    SectionCaseStudyCarouselFooterHeaderComponent,
    SectionCaseStudyCarouselFooterSubHeaderComponent,
    SectionCaseStudyCarouselHeaderComponent,
    SectionCaseStudyCarouselHeaderDetailComponent,
    SectionCaseStudyCarouselItemComponent,
    SectionCaseStudyCarouselSubHeaderComponent,
    SectionCompaniesComponent,
    SectionCompaniesTitleComponent,
    SectionFeatureBodyComponent,
    SectionFeatureButtonComponent,
    SectionFeatureButtonDetailComponent,
    SectionFeatureComponent,
    SectionFeatureDetailComponent,
    SectionFeatureHeadingComponent,
    SectionFeatureSubheadingComponent,
    SectionGalleryComponent,
    SectionGalleryItemComponent,
    SectionHeroBackgroundImageComponent,
    SectionHeroComponent,
    SectionHeroDescriptionComponent,
    SectionHeroDetailTextComponent,
    SectionHeroCustomContentComponent,
    SectionHeroImageDetailComponent,
    SectionHeroLogoComponent,
    SectionHeroTitleComponent,
    SectionHeroVideoBackgroundComponent,
    SectionHomepageFeatureComponent,
    SectionHomepageFeatureTitleComponent,
    SectionHomepageFeatureTopLeftComponent,
    SectionHomepageFeatureTopRightComponent,
    SectionHomepageFeatureBottomLeftComponent,
    SectionHomepageFeatureBottomRightComponent,
    SectionHomepageFeatureFooterComponent,
    SectionHomepageEntryComponent,
    SectionHomepageEntryHeadingComponent,
    SectionHomepageEntryDescriptionComponent,
    SectionHomepageEntryLinkComponent,
    SelectComponent,
    SidebarComponent,
    SidebarCustomTemplateDirective,
    SidebarFooterComponent,
    SidebarHeadingDirective,
    SidebarItemComponent,
    SidebarSubheadingDirective,
    SliderComponent,
    SocialButtonsArrowComponent,
    SocialButtonsComponent,
    SpinnerComponent,
    SplitButtonComponent,
    SplitButtonOptionComponent,
    SplitButtonPrimaryComponent,
    StickyFooterBodyComponent,
    StickyFooterComponent,
    StickyFooterWrapperComponent,
    StoryHeaderComponent,
    TabItemActionComponent,
    TabItemComponent,
    TableColumnComponent,
    TableComponent,
    TableExpandableContentComponent,
    TabsComponent,
    TagComponent,
    TagStatusComponent,
    TextareaComponent,
    TextComponent,
    ThumbnailViewerComponent,
    ThumbnailViewerImageComponent,
    TitleCardComponent,
    TitleCardStatusComponent,
    TitleCardTabNameComponent,
    ToastAlertComponent,
    ToastAlertContainerComponent,
    ToastAlertGlobalComponent,
    ToggleButtonsComponent,
    ToggleComponent,
    UnreadIndicatorComponent,
    UpgradeTagComponent,
    UserAvatarComponent,
    UserCardCarouselComponent,
    UserCardCarouselItemButtonComponent,
    UserCardCarouselItemComponent,
    UserCardCarouselItemDescriptionComponent,
    UsernameComponent,
    ValidationErrorComponent,
    VideoRecordInputComponent,
    ViewHeaderComponent,
    WidgetComponent,
    WidgetButtonComponent,
    WorkedTimeComponent,
  ],
  exports: [
    AnchorScrollModule,
    AnimatedSvgModule,
    AnnotationIndicatorComponent,
    AcceptRateComponent,
    BadgeComponent,
    BannerAlertComponent,
    BannerAnnouncementButtonsComponent,
    BannerAnnouncementComponent,
    BannerAnnouncementMessageComponent,
    BannerUpsellButtonComponent,
    BannerUpsellComponent,
    BannerUpsellBackgroundImageComponent,
    BannerUpsellDescriptionComponent,
    BannerUpsellDisclaimerComponent,
    BannerUpsellPictureComponent,
    BannerUpsellSmallTitleComponent,
    BannerUpsellTitleComponent,
    BestPractisesComponent,
    BitComponent,
    BreadcrumbsComponent,
    BreadcrumbsItemComponent,
    BudgetComponent,
    ButtonComponent,
    CalloutComponent,
    CalloutContentComponent,
    CalloutTriggerComponent,
    CameraComponent,
    CameraInputComponent,
    CardComponent,
    CardHeaderRightComponent,
    CardHeaderSecondaryComponent,
    CardHeaderTitleComponent,
    CardListComponent,
    CardListItemComponent,
    CarouselComponent,
    CarouselItemComponent,
    CarouselNextButtonComponent,
    CarouselPrevButtonComponent,
    ChartBarComponent,
    ChartComponent,
    CheckboxComponent,
    ColumnComponent,
    CompletedJobsComponent,
    ContainerComponent,
    DirectivesModule,
    DirectoryItemComponent,
    DisplayCardComponent,
    DisplayCardContentComponent,
    DisplayCardFooterLeftComponent,
    DisplayCardFooterRightComponent,
    DisplayCardHeaderLeftComponent,
    DisplayCardHeaderRightComponent,
    DisplayCardImageComponent,
    DoBestPractisesComponent,
    DontBestPractisesComponent,
    DrawerComponent,
    DropdownFilterComponent,
    EarningsComponent,
    FileDisplayComponent,
    FileSelectComponent,
    FlagComponent,
    FloatingActionComponent,
    FreelancerAsyncPipeModule,
    GenericErrorComponent,
    GridComponent,
    HeadingComponent,
    HighlightComponent,
    HighlightCalloutComponent,
    HighlightTextComponent,
    HrComponent,
    IconModule,
    ImageCaptionComponent,
    InfiniteScrollComponent,
    InfiniteScrollContainerDirective,
    InputComponent,
    LabelComponent,
    LinkModule,
    ListComponent,
    ListItemBodyComponent,
    ListItemComponent,
    ListItemHeaderComponent,
    LoadingTextComponent,
    LocationInputComponent,
    LogoComponent,
    MapComponent,
    MarginModule,
    ModalTitleDirective,
    MoreOptionsComponent,
    MoreOptionsItemComponent,
    MultipleLocationInputComponent,
    MultiSelectComponent,
    NewFeatureTagComponent,
    NotificationIndicatorComponent,
    OnlineIndicatorComponent,
    PageLayoutComponent,
    PageLayoutPrimaryComponent,
    PageLayoutSecondaryComponent,
    PageLayoutSingleComponent,
    PaginationComponent,
    PictureComponent,
    ProgressBarComponent,
    ProgressStepCalloutComponent,
    ProgressStepContentComponent,
    ProgressStepItemComponent,
    ProgressStepsComponent,
    ProgressSubstepComponent,
    RadioComponent,
    RatingComponent,
    ReactiveFormsModule,
    RelativeTimeComponent,
    ReviewCountComponent,
    RibbonComponent,
    ScrollableContentComponent,
    SearchComponent,
    ShareButtonsComponent,
    ShareLinkComponent,
    SectionArticleComponent,
    SectionArticleButtonComponent,
    SectionArticleDescriptionComponent,
    SectionArticleEntryComponent,
    SectionArticleEntryDetailComponent,
    SectionArticleEntryHeadingComponent,
    SectionGalleryComponent,
    SectionGalleryItemComponent,
    SectionArticleTitleComponent,
    SectionCaseStudyCarouselComponent,
    SectionCaseStudyCarouselFooterComponent,
    SectionCaseStudyCarouselFooterHeaderComponent,
    SectionCaseStudyCarouselFooterSubHeaderComponent,
    SectionCaseStudyCarouselHeaderComponent,
    SectionCaseStudyCarouselHeaderDetailComponent,
    SectionCaseStudyCarouselItemComponent,
    SectionCaseStudyCarouselSubHeaderComponent,
    SectionCompaniesComponent,
    SectionCompaniesTitleComponent,
    SectionFeatureBodyComponent,
    SectionFeatureButtonComponent,
    SectionFeatureButtonDetailComponent,
    SectionFeatureComponent,
    SectionFeatureDetailComponent,
    SectionFeatureHeadingComponent,
    SectionFeatureSubheadingComponent,
    SectionHeroBackgroundImageComponent,
    SectionHeroComponent,
    SectionHeroDescriptionComponent,
    SectionHeroDetailTextComponent,
    SectionHeroLogoComponent,
    SectionHeroImageDetailComponent,
    SectionHeroCustomContentComponent,
    SectionHeroTitleComponent,
    SectionHeroVideoBackgroundComponent,
    SectionHomepageFeatureComponent,
    SectionHomepageFeatureTitleComponent,
    SectionHomepageFeatureTopLeftComponent,
    SectionHomepageFeatureTopRightComponent,
    SectionHomepageFeatureBottomLeftComponent,
    SectionHomepageFeatureBottomRightComponent,
    SectionHomepageFeatureFooterComponent,
    SectionHomepageEntryHeadingComponent,
    SectionHomepageEntryDescriptionComponent,
    SectionHomepageEntryLinkComponent,
    SectionHomepageEntryComponent,
    SelectComponent,
    SidebarComponent,
    SidebarCustomTemplateDirective,
    SidebarHeadingDirective,
    SidebarSubheadingDirective,
    SidebarItemComponent,
    SidebarFooterComponent,
    SliderComponent,
    SocialButtonsArrowComponent,
    SocialButtonsComponent,
    SpinnerComponent,
    SplitButtonComponent,
    StickyFooterBodyComponent,
    StickyFooterComponent,
    StickyFooterWrapperComponent,
    StoryHeaderComponent,
    SplitButtonOptionComponent,
    SplitButtonPrimaryComponent,
    StickyModule,
    TabItemActionComponent,
    TabItemComponent,
    TableColumnComponent,
    TableComponent,
    TableExpandableContentComponent,
    TabsComponent,
    TagComponent,
    TagStatusComponent,
    TextareaComponent,
    TextComponent,
    ThumbnailViewerComponent,
    ThumbnailViewerImageComponent,
    TitleCardComponent,
    TitleCardStatusComponent,
    TitleCardTabNameComponent,
    ToastAlertComponent,
    ToastAlertContainerComponent,
    ToastAlertGlobalComponent,
    ToggleButtonsComponent,
    ToggleComponent,
    UnreadIndicatorComponent,
    UpgradeTagComponent,
    UserAvatarComponent,
    UserCardCarouselComponent,
    UserCardCarouselItemButtonComponent,
    UserCardCarouselItemComponent,
    UserCardCarouselItemDescriptionComponent,
    UsernameComponent,
    ValidationErrorComponent,
    VideoModule,
    VideoRecordInputComponent,
    ViewHeaderComponent,
    WidgetComponent,
    WidgetButtonComponent,
    WorkedTimeComponent,
  ],
  imports: [
    AnchorScrollModule,
    AnimatedSvgModule,
    GoogleMapsModule,
    CommonModule,
    DirectivesModule,
    HammerModule,
    IconModule,
    LinkModule,
    MarginModule,
    FreelancerAsyncPipeModule,
    MatDatepickerModule,
    ObserversModule,
    OverlayModule,
    PipesModule,
    PortalModule,
    /**
     * FIXME: T280638 - In Angular 15, a breaking change was introduced:
     * https://angular.io/guide/update-to-version-15#setdisabledstate-is-always-called-when-a-controlvalueaccessor-is-attached)
     *
     * This caused [attr.disabled] to not disable the `<select>` element as it is in sync with FormControl. Disabling the
     * FormControl will disable the `<select>` element, but it's value be omitted when using `FormGroup.value`, and this
     * may cause regression issues.
     *
     * https://github.com/angular/angular/issues/48350#issuecomment-1405900515
     */
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    RouterModule,
    StickyModule,
    VideoModule,
    ScrollingModule,
    MatTooltipModule,
  ],
  // /!\ DO NOT ADD PROVIDERS HERE
})
export class UiModule {
  static initialize(config: UiConfig): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [
        {
          provide: LIGHTGALLERY_CONFIG,
          useValue: {
            licenseKey: config.lightGalleryLicenseKey,
          } as LightGalleryConfig,
        },
        { provide: UI_CONFIG, useValue: config },
        LocalizedDateFns,
        {
          provide: DateAdapter,
          useClass: DateFnsDateAdapter,
          deps: [LocalizedDateFns],
        },
        {
          provide: MAT_DATE_FORMATS,
          useValue: MAT_DATEFNS_DATE_FORMATS,
        },
        {
          provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
          useClass: DefaultMatCalendarRangeStrategy,
        },
        OpenNativeSettings,
      ],
    };
  }
}
