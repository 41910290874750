import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import type { OnDestroy } from '@angular/core';
import {
  inject,
  Injectable,
  PLATFORM_ID,
  RendererFactory2,
} from '@angular/core';
import { LocalStorage } from '@freelancer/local-storage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Observable } from 'rxjs';
import { firstValueFrom, fromEvent, of, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import type { Theme, ThemeSetting } from './theme.types';

@UntilDestroy({ className: 'ThemeService' })
@Injectable({
  providedIn: 'root',
})
export class ThemeService implements OnDestroy {
  private document = inject(DOCUMENT);
  private localStorage = inject(LocalStorage);
  private platformId = inject(PLATFORM_ID);
  private renderer = inject(RendererFactory2).createRenderer(null, null);

  private readonly DARK_MODE_MEDIA_QUERY = '(prefers-color-scheme: dark)';

  /**
   * themeSetting is the actual setting that the user has chosen (or been defaulted to).
   * This can be: 'light' | 'dark' | 'system'. This setting is stored in local storage.
   */
  private readonly themeSetting$: Observable<ThemeSetting>;
  /**
   * Theme is the actual theme being used in the app. This is either 'light' or 'dark'.
   * If the themeSetting is 'system', then the theme will be 'light' or 'dark' based on the user's OS settings.
   */
  private readonly theme$: Observable<Theme>;
  private subscription = new Subscription();

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      // The setting that the user has selected.
      this.themeSetting$ = this.localStorage.get('theme').pipe(
        map(theme => theme ?? 'light'),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

      // Listen for changes to the system theme.
      this.subscription.add(
        fromEvent(
          window.matchMedia(this.DARK_MODE_MEDIA_QUERY),
          'change',
        ).subscribe(async event => {
          if (!('matches' in event)) {
            return;
          }

          const setting = await firstValueFrom(
            this.themeSetting$.pipe(untilDestroyed(this)),
          );

          if (setting === 'system') {
            this.applyTheme(event.matches ? 'dark' : 'light');
          }
        }),
      );

      // The actual theme being used in the app.
      // Derived from the themeSetting and the system theme.
      this.theme$ = this.themeSetting$.pipe(
        map(theme =>
          theme === 'system'
            ? window.matchMedia(this.DARK_MODE_MEDIA_QUERY).matches
              ? 'dark'
              : 'light'
            : theme,
        ),
      );
    } else {
      this.themeSetting$ = of('light');
      this.theme$ = of('light');
    }
  }

  getThemeSetting(): Observable<ThemeSetting> {
    return this.themeSetting$;
  }

  getTheme(): Observable<Theme> {
    return this.theme$;
  }

  async setThemeSetting(theme: ThemeSetting): Promise<void> {
    await this.localStorage.set('theme', theme);
  }

  applyTheme(theme: Theme): void {
    if (isPlatformBrowser(this.platformId)) {
      const docElement = this.document.documentElement;
      if (theme === 'dark') {
        this.renderer.addClass(docElement, 'dark');
      } else {
        this.renderer.removeClass(docElement, 'dark');
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
