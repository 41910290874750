import { toNumber } from '@freelancer/utils';
import type { TimezoneApi } from 'api-typings/common/common';
import type { Timezone } from './timezones.model';

export function transformTimezone(timezone: TimezoneApi): Timezone {
  // Make sure all fields are required since TimezoneApi makes
  // the fields optional.
  return {
    id: timezone.id ?? 0,
    country: timezone.country ?? '',
    timezone: timezone.timezone ?? '',
    offset: transformTimezoneOffset(timezone.offset ?? 0),
  };
}

export function transformTimezoneApi(
  timezone: Timezone | Partial<Timezone>,
): TimezoneApi {
  return {
    ...timezone,
    offset: timezone.offset ? transformTimezoneGMT(timezone.offset) : undefined,
  };
}

/**
 This function accepts floats like `9.0`, `3.5`, `-7.75`
 and converts them into Greenwich Mean Time (GMT).
 The conversion results in `+0900`, `+0330`, `-0745`
 */
function transformTimezoneOffset(offset: number): string {
  const absOffset = Math.abs(offset);
  const hours = Math.floor(absOffset);
  const minutes = 60 * (absOffset - hours);
  const sign = offset > 0 ? '+' : '-';
  const hoursPart = hours > 9 ? `${hours}` : `0${hours}`;
  const minutesPart = minutes > 9 ? `${minutes}` : `0${minutes}`;
  return `${sign}${hoursPart}${minutesPart}`;
}

/**
 * This function accepts Greenwich Mean Time (GMT) timezone
 * string and converts to timezone offset value.
 */
function transformTimezoneGMT(gmt: string): number {
  const [, sign, hours, minutes] = gmt.match(/([-|+])(\d{2})(\d{2})/) || [];
  const offset = toNumber(hours) + toNumber(minutes) / 60;
  return offset * (sign === '+' ? 1 : -1);
}
