import type {
  AfterViewInit,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { RepetitiveSubscription } from '@freelancer/decorators';
import type { Observable } from 'rxjs';
import { ReplaySubject, Subscription, switchMap } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { Assets } from '../assets';
import { FontWeight } from '../text';
import { IconService } from './icon.service';
import {
  HoverColor,
  IconBackdrop,
  IconBackdropSize,
  IconBackdropStyle,
  IconClickAnimation,
  IconColor,
  IconHoverAnimation,
  IconSize,
} from './icon.types';

@Component({
  selector: 'fl-icon',
  template: `
    <ng-container *ngIf="useIconFont">
      <span
        class="IconFont"
        [attr.data-backdrop]="backdrop"
        [attr.data-color]="color"
        [attr.data-disabled]="disabled"
        [attr.data-drop-shadow]="dropShadow"
        [attr.data-hover-color]="hoverColor"
        [attr.data-name]="name"
        [attr.data-role]="iconRole"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-large]="sizeDesktopLarge"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-use-icon-font]="true"
        [attr.data-weight]="weight"
        [attr.tabindex]="clickable ? '-1' : null"
        [attr.title]="label"
        [attr.data-legacy]="attrLegacy"
        [attr.aria-hidden]="!attrAriaHidden"
      ></span>
    </ng-container>

    <div
      #container
      *ngIf="!useIconFont"
      class="IconContainer"
      [attr.aria-hidden]="!attrAriaHidden"
      [attr.data-color]="color"
      [attr.data-disabled]="disabled"
      [attr.data-drop-shadow]="dropShadow"
      [attr.data-hover-color]="hoverColor"
      [attr.data-name]="name"
      [attr.data-size]="size"
      [attr.data-size-tablet]="sizeTablet"
      [attr.data-size-desktop]="sizeDesktop"
      [attr.data-size-desktop-large]="sizeDesktopLarge"
      [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
      [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
      [attr.data-legacy]="attrLegacy"
      [attr.tabindex]="clickable ? '-1' : null"
      [attr.title]="label"
    ></div>
  `,
  styleUrls: ['./icon.component.scss', './icon-font.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent
  implements OnChanges, OnDestroy, AfterViewInit, OnInit
{
  attrLegacy?: true;
  attrAriaHidden?: true;

  @RepetitiveSubscription()
  private iconSubscription?: Subscription;

  @Input() color = IconColor.FOREGROUND;
  @Input() name: string;
  @Input() hoverColor?: HoverColor;
  @Input() clickable = false;
  @Input() dropShadow = false;

  /** Aria label used for accessibility */
  @HostBinding('attr.aria-label')
  @Input()
  label?: string;

  /** Change the icon backdrop */
  @HostBinding('attr.data-backdrop')
  @Input()
  backdrop?: IconBackdrop;

  @HostBinding('attr.data-backdrop-style')
  @Input()
  backdropStyle: IconBackdropStyle = IconBackdropStyle.SOLID;

  @HostBinding('attr.data-backdrop-size')
  @Input()
  backdropSize: IconBackdropSize;

  /** Icon size for mobile and above */
  @HostBinding('attr.data-size')
  @Input()
  size = IconSize.MID;

  /** Change the [size] from tablet and above */
  @HostBinding('attr.data-size-tablet')
  @Input()
  sizeTablet?: IconSize;

  /** Change the [size] from desktop and above */
  @HostBinding('attr.data-size-desktop')
  @Input()
  sizeDesktop?: IconSize;

  /** Change the [size] from desktop-large and above */
  @HostBinding('attr.data-size-desktop-large')
  @Input()
  sizeDesktopLarge?: IconSize;

  /** Change the [size] from desktop-xlarge and above */
  @HostBinding('attr.data-size-desktop-xlarge')
  @Input()
  sizeDesktopXLarge?: IconSize;

  /** Change the [size] from desktop-xxlarge and above */
  @HostBinding('attr.data-size-desktop-xxlarge')
  @Input()
  sizeDesktopXXLarge?: IconSize;

  @HostBinding('attr.data-disabled')
  @Input()
  disabled = false;

  /** flicon/legacy icon system compatibility layer for the new stack
   *  Note: This is temporary for navigation. DO NOT USE
   */
  @Input() set legacy(value: boolean) {
    this.attrLegacy = value ? true : undefined;
  }

  @HostBinding('attr.role')
  get iconRole(): string {
    return this.clickable ? 'button' : 'img';
  }

  @HostBinding('attr.tabindex')
  get iconTabIndex(): number | null {
    return this.clickable ? 0 : null;
  }

  /** Loads Google Material Symbol instead of SVG */
  @HostBinding('attr.data-use-icon-font')
  @Input()
  useIconFont = false;

  /** Sets the font weight of the icon. Only valid when useIconFont is true */
  @Input() weight: FontWeight = FontWeight.NORMAL;

  /** Sets fill of Google Material Symbol icons. Only valid when useIconFont is true */
  @HostBinding('attr.data-fill-icon-font')
  @Input()
  fillIconFont = false;

  @HostBinding('attr.data-click-animation')
  @Input()
  clickAnimation?: IconClickAnimation;

  @HostBinding('attr.data-hover-animation')
  @Input()
  hoverAnimation?: IconHoverAnimation;

  @ViewChild('container') container: any;

  private fetchedIcon$: Observable<Element>;
  private iconNameSubject$ = new ReplaySubject<string>(1);

  constructor(
    private renderer: Renderer2,
    private iconService: IconService,
    private assets: Assets,
  ) {}

  private setElement(element: Element): void {
    const layoutElement: Element = this.container.nativeElement;
    // Detach all views of the child nodes of the root element.
    // NOTE: This wouldn't work in IE 11 because there's a bug there that
    // causes SVG to not be included in the list.
    // We've polyfilled it though.
    // Ref: https://github.com/angular/angular/issues/6327
    Array.from(layoutElement.childNodes).forEach((node: Node) => {
      this.renderer.removeChild(layoutElement, node);
    });

    // Put the new icon SVG in.
    this.renderer.appendChild(layoutElement, element);
  }

  ngOnInit(): void {
    this.fetchedIcon$ = this.iconNameSubject$.asObservable().pipe(
      distinctUntilChanged(),
      switchMap(name => {
        return this.iconService
          .getIcon(
            this.assets.getUrl(`icons/${name}.svg`),
            this.renderer,
            this.label || name,
          )
          .pipe(take(1));
      }),
    );
  }

  ngAfterViewInit(): void {
    this.iconSubscription = this.fetchedIcon$.subscribe(element => {
      this.setElement(element);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.useIconFont && 'name' in changes) {
      if (changes.name.currentValue) {
        this.iconNameSubject$.next(this.name);
      }

      if ('label' in changes) {
        this.attrAriaHidden = changes.name.currentValue ? undefined : true;
      }
    }

    if (
      'hoverAnimation' in changes ||
      ('clickable' in changes && !this.disabled)
    ) {
      // Default to `highlight` if icon is clickable (and not disabled) and no hover behavior is set
      this.hoverAnimation =
        this.clickable && !this.hoverAnimation
          ? 'highlight'
          : this.hoverAnimation;
    }

    if (
      'clickAnimation' in changes ||
      ('clickable' in changes && !this.disabled)
    ) {
      // Default to `highlight` if icon is clickable (and not disabled) and no click behavior is set
      this.clickAnimation =
        this.clickable && !this.clickAnimation
          ? 'highlight'
          : this.clickAnimation;
    }
  }

  ngOnDestroy(): void {
    if (this.iconSubscription !== undefined) {
      this.iconSubscription.unsubscribe();
    }
  }
}
