import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { TextAlign, TextSize, TextTransform } from '@freelancer/ui/text';
import { HeadingColor, HeadingType, HeadingWeight } from './heading.types';

@Component({
  selector: 'fl-heading',
  template: `
    <ng-container [ngSwitch]="headingType">
      <h1
        *ngSwitchCase="HeadingType.H1"
        [attr.data-color]="color"
        [attr.data-inline]="inline"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-text-transform]="textTransform"
        [attr.data-truncate]="truncate"
        [attr.data-weight]="weight"
        [attr.data-weight-tablet]="weightTablet"
        [attr.data-weight-desktop]="weightDesktop"
        [attr.data-weight-desktop-xlarge]="weightDesktopXLarge"
        [attr.data-weight-desktop-xxlarge]="weightDesktopXXLarge"
      >
        <ng-container *ngTemplateOutlet="injectedContent"></ng-container>
      </h1>

      <h2
        *ngSwitchCase="HeadingType.H2"
        [attr.data-color]="color"
        [attr.data-inline]="inline"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-text-transform]="textTransform"
        [attr.data-truncate]="truncate"
        [attr.data-weight]="weight"
        [attr.data-weight-tablet]="weightTablet"
        [attr.data-weight-desktop]="weightDesktop"
        [attr.data-weight-desktop-xlarge]="weightDesktopXLarge"
        [attr.data-weight-desktop-xxlarge]="weightDesktopXXLarge"
      >
        <ng-container *ngTemplateOutlet="injectedContent"></ng-container>
      </h2>

      <h3
        *ngSwitchCase="HeadingType.H3"
        [attr.data-color]="color"
        [attr.data-inline]="inline"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-text-transform]="textTransform"
        [attr.data-truncate]="truncate"
        [attr.data-weight]="weight"
        [attr.data-weight-tablet]="weightTablet"
        [attr.data-weight-desktop]="weightDesktop"
        [attr.data-weight-desktop-xlarge]="weightDesktopXLarge"
        [attr.data-weight-desktop-xxlarge]="weightDesktopXXLarge"
      >
        <ng-container *ngTemplateOutlet="injectedContent"></ng-container>
      </h3>

      <h4
        *ngSwitchCase="HeadingType.H4"
        [attr.data-color]="color"
        [attr.data-inline]="inline"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-text-transform]="textTransform"
        [attr.data-truncate]="truncate"
        [attr.data-weight]="weight"
        [attr.data-weight-tablet]="weightTablet"
        [attr.data-weight-desktop]="weightDesktop"
        [attr.data-weight-desktop-xlarge]="weightDesktopXLarge"
        [attr.data-weight-desktop-xxlarge]="weightDesktopXXLarge"
      >
        <ng-container *ngTemplateOutlet="injectedContent"></ng-container>
      </h4>

      <h5
        *ngSwitchCase="HeadingType.H5"
        [attr.data-color]="color"
        [attr.data-inline]="inline"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-text-transform]="textTransform"
        [attr.data-truncate]="truncate"
        [attr.data-weight]="weight"
        [attr.data-weight-tablet]="weightTablet"
        [attr.data-weight-desktop]="weightDesktop"
        [attr.data-weight-desktop-xlarge]="weightDesktopXLarge"
        [attr.data-weight-desktop-xxlarge]="weightDesktopXXLarge"
      >
        <ng-container *ngTemplateOutlet="injectedContent"></ng-container>
      </h5>

      <h6
        *ngSwitchCase="HeadingType.H6"
        [attr.data-color]="color"
        [attr.data-inline]="inline"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-size-desktop-xlarge]="sizeDesktopXLarge"
        [attr.data-size-desktop-xxlarge]="sizeDesktopXXLarge"
        [attr.data-text-transform]="textTransform"
        [attr.data-truncate]="truncate"
        [attr.data-weight]="weight"
        [attr.data-weight-tablet]="weightTablet"
        [attr.data-weight-desktop]="weightDesktop"
        [attr.data-weight-desktop-xlarge]="weightDesktopXLarge"
        [attr.data-weight-desktop-xxlarge]="weightDesktopXXLarge"
      >
        <ng-container *ngTemplateOutlet="injectedContent"></ng-container>
      </h6>
      <ng-template #injectedContent>
        <ng-content></ng-content>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent {
  HeadingType = HeadingType;
  TextAlign = TextAlign;
  TextSize = TextSize;
  HeadingColor = HeadingColor;

  @Input() color = HeadingColor.FOREGROUND;

  /** Font size for mobile and above */
  @Input() size: TextSize;

  /** Change the [size] from tablet and above */
  @Input() sizeTablet?: TextSize;

  /** Change the [size] and/or [sizeTablet] from desktop and above */
  @Input() sizeDesktop?: TextSize;

  /** Change the [size], [sizeTablet] and [sizeDesktop] from desktop-large and above */
  @Input() sizeDesktopLarge?: TextSize;

  /** Change the [size], [sizeTablet] and [sizeDesktop] and [sizeDesktopLarge] from desktop-xlarge and above */
  @Input() sizeDesktopXLarge?: TextSize;

  /** Change the [size], [sizeTablet], [sizeDesktop] and [sizeDesktopXLarge] from desktop-xxlarge and above */
  @Input() sizeDesktopXXLarge?: TextSize;

  /** Font weight for mobile and above */
  @Input() weight = HeadingWeight.BOLD;

  /** Change the [weight] from tablet and above */
  @Input() weightTablet?: HeadingWeight;

  /** Change the [weight] and/or [weightTablet] from desktop and above */
  @Input() weightDesktop?: HeadingWeight;

  /** Change the [weight], [weightTablet] and [weightDesktop] from desktop-large and above */
  @Input() weightDesktopLarge?: HeadingWeight;

  /** Change the [weight], [weightTablet] and [weightDesktop] and [weightDesktopLarge] from desktop-xlarge and above */
  @Input() weightDesktopXLarge?: HeadingWeight;

  /** Change the [weight], [weightTablet], [weightDesktop] and [weightDesktopXLarge] from desktop-xxlarge and above */
  @Input() weightDesktopXXLarge?: HeadingWeight;

  @Input() textTransform?: TextTransform;

  @HostBinding('attr.data-text-align')
  @Input()
  textAlign?: TextAlign;

  @HostBinding('attr.data-text-align-tablet')
  @Input()
  textAlignTablet?: TextAlign;

  @HostBinding('attr.data-text-align-desktop-small')
  @Input()
  textAlignDesktopSmall?: TextAlign;

  /** Defines the HTML heading node it will use e.g (<h1>, <h2>, etc..)
   *  Note: This is for semantics usage and does not define the size of the heading
   */
  @Input() headingType: HeadingType;

  /**
   * Truncate text node and adds ellipsis where word is cutoff
   * Note: This will truncate mid-word if needed to keep things in a single line
   * since headings are not expected to contain paragraphs.
   */
  @Input() truncate = false;

  @Input() inline = false;
}
