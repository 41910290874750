import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Pwa } from '@freelancer/pwa';
import { Tracking } from '@freelancer/tracking';
// This is to prevent circular dependency issues, as both this service and
// camera-input.component.ts require modals.
// eslint-disable-next-line local-rules/validate-freelancer-imports
import { ModalSize } from '../modal/modal-size';
import { ModalService } from '../modal/modal.service';
import {
  permissionCheck,
  PermissionDenyReason,
  PermissionStateEnum,
} from './permissions.model';
import type { PermissionStateType, PermissionType } from './permissions.model';

@Injectable({ providedIn: 'root' })
export class Permissions {
  private permissionsRequestDisabled = false;

  constructor(
    private modalService: ModalService,
    private pwa: Pwa,
    private tracking: Tracking,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}
  /**
   * For each request function, the first section checks the permission status
   * for native.
   *
   * In the case that it is granted, it will set res = true, and follow
   * the default behaviour of returning res.
   *
   * In the case it has not been granted/denied, it will show the modal,
   * and once the modal has been closed, it will either:
   *
   * a) request permissions using the native library if the
   *    user allowed it on the app modal, or
   * b) set res = false.
   *
   * Otherwise, if it has already been previously denied, it will set res
   * = false.
   *
   * Using res, it will determine whether to show the recovery modal.
   *
   * The second section checks the permission status for browser.
   *
   * This will use the Navigator Permissions API to check for
   * permissions.
   *
   * The reason this is wrapped in a try/catch is because some browsers
   * namely some versions of Safari, might not have access to this API.
   *
   * In the case that a browser does not have access to this API, we
   * will set res = true, which would then not bother requesting
   * using the modal. This should mean that this allows the browser to
   * handle things the existing way - which is by calling the API for a
   * result, which triggers requesting permissions, etc.
   *
   * In the case that it is granted, it will set res = true, and follow
   * the default behaviour of returning res.
   *
   * In the case it has not been granted/denied, it will show the modal,
   * and once the modal has been closed, it will either:
   *
   * a) set res = false, or
   * b) set res = true, and allow the `onchange` function to display
   *    the recovery modal should it be required.
   *
   * The reason that res = true is to bypass showing the recovery
   * modal. This will allow the browser to handle things the existing
   * way - which is by calling the API for a result, which triggers
   * permission request, etc.
   *
   * Otherwise, if it has already been previously denied, it will set res
   * = false.
   *
   * Using res, it will determine whether to show the recovery modal.
   */
  async requestPermissions(
    permission: PermissionType,
    reason: string,
    isLegacyFlow: boolean = false,
  ): Promise<boolean> {
    if (this.permissionsRequestDisabled) {
      return false;
    }

    if (isPlatformBrowser(this.platformId)) {
      let res: boolean | undefined;
      let permissionDenyReason: PermissionDenyReason | undefined;

      // isLegacyFlow is to force using the normal web APIs to request for
      // permissions. This is required for the audio/video call function on
      // older versions of the Android app, as unfortunately the version gate
      // does not throw Android users into the browser.
      if (this.pwa.isNative() && !isLegacyFlow) {
        const capacitorPermissionStatus = await permissionCheck[
          permission
        ].nativeCheck();

        switch (capacitorPermissionStatus) {
          case PermissionStateEnum.GRANTED: {
            res = true;
            break;
          }
          case PermissionStateEnum.PROMPT:
          case PermissionStateEnum.PROMPT_WITH_RATIONALE: {
            const modalRef = this.modalService.open('PermissionsRequestModal', {
              inputs: {
                permission,
                reason,
              },
              closeable: false,
              showBackdrop: true,
            });

            res = await modalRef.afterClosed().then(modalRes => {
              if (modalRes) {
                return permissionCheck[permission]
                  .nativeRequest()
                  .then(nativePermission => {
                    if (nativePermission === PermissionStateEnum.GRANTED) {
                      // Track if the permission was granted on mobile native.
                      this.tracking.track('user_action', {
                        section: 'GeneralPermissions',
                        subsection: 'PermissionsRequestModal',
                        label: 'AcceptFromNative',
                        action: 'click',
                        extra_params: {
                          permission,
                          permission_reason: reason,
                        },
                      });
                      return true;
                    }

                    permissionDenyReason = PermissionDenyReason.APP;

                    // Track if the permission was denied on the OS in mobile
                    // native.
                    this.tracking.track('user_action', {
                      section: 'GeneralPermissions',
                      subsection: 'PermissionsRequestModal',
                      label: 'RejectFromNative',
                      action: 'click',
                      extra_params: {
                        permission,
                        permission_reason: reason,
                        permission_deny_reason: permissionDenyReason,
                      },
                    });

                    return false;
                  });
              }

              permissionDenyReason = PermissionDenyReason.USER_DENIED_FROM_APP;

              return false;
            });
            break;
          }
          case PermissionStateEnum.DENIED: {
            permissionDenyReason = PermissionDenyReason.APP;
            res = false;
            break;
          }
          default:
            break; // Do nothing
        }
      } else {
        // `as any` is required to allow camera permissions to be requested from
        // the very same API.
        try {
          const windowRes = await permissionCheck[permission].webCheck();

          switch (windowRes.state) {
            case PermissionStateEnum.GRANTED: {
              res = true;
              break;
            }
            case PermissionStateEnum.PROMPT: {
              const modalRef = this.modalService.open(
                'PermissionsRequestModal',
                {
                  inputs: {
                    permission,
                    reason,
                  },
                  closeable: false,
                  showBackdrop: true,
                  size: ModalSize.MID,
                },
              );

              res = await modalRef.afterClosed().then(modalRes => {
                if (!modalRes) {
                  permissionDenyReason =
                    PermissionDenyReason.USER_DENIED_FROM_APP;

                  return false;
                }

                windowRes.onchange = (e: Event) => {
                  permissionDenyReason = PermissionDenyReason.APP;
                  if ((e.target as any).state !== PermissionStateEnum.GRANTED) {
                    // Track that the permission has been denied on the browser.
                    this.tracking.track('user_action', {
                      section: 'GeneralPermissions',
                      subsection: 'PermissionsRequestModal',
                      label: 'RejectFromBrowser',
                      action: 'click',
                      extra_params: {
                        permission,
                        permission_reason: reason,
                        permission_deny_reason: permissionDenyReason,
                      },
                    });

                    this.modalService.open('PermissionsRecoveryModal', {
                      inputs: {
                        permission,
                        permissionReason: reason,
                        permissionDenyReason,
                      },
                      showBackdrop: true,
                      size: ModalSize.MID,
                    });
                  } else {
                    // In the case it was granted on browser, let's track that.
                    this.tracking.track('user_action', {
                      section: 'GeneralPermissions',
                      subsection: 'PermissionsRequestModal',
                      label: 'AcceptFromBrowser',
                      action: 'click',
                      extra_params: {
                        permission,
                        permission_reason: reason,
                      },
                    });
                  }
                  windowRes.onchange = () => undefined;
                };

                return true;
              });
              break;
            }
            case PermissionStateEnum.DENIED: {
              permissionDenyReason = PermissionDenyReason.APP;
              res = false;
              break;
            }
            default:
              break; // Do nothing
          }
        } catch {
          // In the case that we're working with a browser that does not have
          // the permissions query, we'll let it slide and continue on.
          // This usually would catch that case.
          this.tracking.track('user_action', {
            section: 'GeneralPermissions',
            subsection: 'PermissionsRequestModal',
            label: 'Unknown',
            extra_params: {
              permission,
              permission_reason: reason,
              permission_deny_reason: permissionDenyReason,
            },
          });
          res = true;
        }
      }

      if (!res) {
        // Native should trigger this if the permission was not granted.
        // Browser should trigger this if permission was denied from modal.
        this.modalService.open('PermissionsRecoveryModal', {
          inputs: {
            permission,
            permissionReason: reason,
            permissionDenyReason:
              permissionDenyReason ?? PermissionDenyReason.OTHER,
          },
          showBackdrop: true,
          size: ModalSize.MID,
        });
      }

      return res ?? false;
    }
    return false;
  }

  async checkPermissions(
    permission: PermissionType,
  ): Promise<PermissionStateType> {
    if (isPlatformBrowser(this.platformId)) {
      if (this.pwa.isNative()) {
        const capacitorPermissionStatus = await permissionCheck[
          permission
        ].nativeCheck();

        return capacitorPermissionStatus;
      }
      // `as any` is required to allow camera permissions to be requested from
      // the very same API.
      try {
        const windowRes = await permissionCheck[permission].webCheck();

        return windowRes.state;
      } catch {
        return PermissionStateEnum.DENIED;
      }
    }
    return PermissionStateEnum.DENIED;
  }

  disablePermissionRequest(): void {
    this.permissionsRequestDisabled = true;
  }
}
