export enum FontType {
  PARAGRAPH = 'paragraph',
  SPAN = 'span',
  STRONG = 'strong',
  CONTAINER = 'container',
}

export enum TextSize {
  XXXSMALL = 'xxxsmall',
  XXSMALL = 'xxsmall',
  XSMALL = 'xsmall',
  SMALL = 'small',
  MARKETING_SMALL = 'marketing_small',
  MID = 'mid',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
  XXXLARGE = 'xxxlarge',
  MARKETING_MEGA = 'marketing_mega',
  MARKETING_XMEGA = 'marketing_xmega',
  INHERIT = 'inherit',
}

export enum FontWeight {
  BOLD = 'bold',
  MEDIUM = 'medium',
  NORMAL = 'normal',
  LIGHT = 'light',
  INHERIT = 'inherit',
}

export enum FontStyle {
  INHERIT = 'inherit',
  ITALIC = 'italic',
  NORMAL = 'normal',
}

export enum TextAlign {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum TextTransform {
  CAPITALIZE = 'capitalize',
  LOWERCASE = 'lowercase',
  UPPERCASE = 'uppercase',
}

export enum ReadMore {
  ICON = 'icon',
  LINK = 'link',
  NONE = 'none',
}

export enum ReadMoreColor {
  LIGHT = 'light',
}

export enum FontColor {
  INHERIT = 'inherit',
  FOREGROUND = 'foreground',
  DARK = 'dark',
  LIGHT = 'light',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum HighlightColor {
  BLUE = 'blue',
  GRAY = 'gray',
}
