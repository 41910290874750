import { assertNever } from '@freelancer/utils';
import {
  UserChosenRoleApi,
  UserSanctionPenaltyTypeApi,
} from 'api-typings/users/users';
import type { Country } from '../countries/countries.model';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import { defaultReputations } from '../freelancer-reputations/freelancer-reputations.seed';
import type { CorporateDetails } from '../profile-view-users/profile-view-users.model';
import type { Qualification } from '../project-view-projects/project-view-projects.model';
import {
  generateFreelancerReputationObjects,
  generateReputationDataObject,
  generateReputationObject,
} from '../reputation/reputation-data.seed';
import type { Reputation } from '../reputation/reputation.model';
import type { Skill } from '../skills/skills.model';
import { graphicDesignSkill, phpSkill } from '../skills/skills.seed';
import type { User } from '../users/users.model';
import { generateUserObject, verifiedUser } from '../users/users.seed';
import type { Badge } from './badge.model';
import type { Location } from './location.model';
import { LocationCountryCode, LocationPreset } from './location.model';
import { generateLocationObject, getLocationPresets } from './location.seed';
import type {
  MembershipPackage,
  ProjectViewUser,
  UserSanction,
} from './project-view-users.model';
import type { UserStatus } from './user-status.model';

export interface GenerateProjectViewUsersOptions {
  readonly users: readonly User[];
  readonly currencyCode?: CurrencyCode;
  readonly reputation?: readonly Reputation[];
  readonly location?: Location;
}

export function generateProjectViewUserObjects({
  users,
  currencyCode = CurrencyCode.USD,
  location,
  reputation,
}: GenerateProjectViewUsersOptions): readonly ProjectViewUser[] {
  const reputationObjects =
    reputation ??
    generateFreelancerReputationObjects({
      userIds: users.map(user => user.id),
    });

  return users.map((user, index) => ({
    ...generateProjectViewUserObject({
      user,
      currencyCode,
      reputation: reputationObjects[index],
      location,
    }),
  }));
}

export interface GenerateProjectViewUserOptions {
  readonly user: User;
  readonly avatar?: string;
  readonly avatarLarge?: string;
  readonly currencyCode?: CurrencyCode;
  readonly skills?: readonly Skill[];
  readonly membershipPackage?: MembershipPackage;
  readonly location?: Location;
  readonly preferredFreelancer?: boolean;
  readonly registrationDate?: number;
  readonly reputation?: Reputation;
  readonly status?: UserStatus;
  readonly tagLine?: string;
  readonly sanctions?: readonly UserSanction[];
  readonly escrowComInteractionRequired?: boolean;
  readonly hasLinkedEscrowComAccount?: boolean;
  readonly completedProjectAsEmployer?: number;
  readonly primaryLanguage?: string;
  readonly corporate?: CorporateDetails;
  readonly isShareholder?: boolean;
  readonly isRisingStar?: boolean;
  readonly closed?: boolean;
}

export function generateProjectViewUserObject({
  user,
  currencyCode = CurrencyCode.USD,
  skills = generateSkills(),
  location = getProjectViewUserLocations(LocationPreset.SEATTLE),
  membershipPackage = generateFreeMembershipPackage(),
  preferredFreelancer = false,
  registrationDate = Date.now(),
  reputation = generateReputationObject({ userIds: [user.id] })[0],
  tagLine = 'This is my tagline.',
  sanctions = [],
  completedProjectAsEmployer,
  primaryLanguage,
  corporate,
  isShareholder = false,
  isRisingStar = false,
  closed = false,
}: GenerateProjectViewUserOptions): ProjectViewUser {
  const {
    id,
    username,
    displayName,
    role,
    chosenRole,
    status,
    poolIds,
    enterpriseIds,
    enterpriseInternalNames,
    escrowComInteractionRequired,
    hasLinkedEscrowComAccount,
    avatar,
  } = user;

  return {
    ...generateUserObject({
      userId: id,
      username,
      displayName,
      role,
      chosenRole,
      currencyCode,
      userStatus: status,
      poolIds,
      enterpriseIds,
      enterpriseInternalNames,
      escrowComInteractionRequired,
      hasLinkedEscrowComAccount,
    }),
    avatar,
    avatarLarge: avatar,
    badges: generateBadges(),
    skills,
    membershipPackage,
    preferredFreelancer,
    primaryCurrency: generateCurrencyObject(currencyCode),
    registrationDate,
    qualifications: generateQualifications(),
    chosenRole: chosenRole || UserChosenRoleApi.EMPLOYER,
    reputation,
    isNewFreelancer: reputation
      ? transformIsNewFreelancer(reputation)
      : undefined,
    tagLine,
    status: status || verifiedUser().userStatus,
    location,
    sanctions,
    employerReputation: completedProjectAsEmployer
      ? generateReputationObject({
          userIds: [user.id],
          reputationOptions: {
            completePercentage: { min: 1, max: 1 },
            total: {
              min: completedProjectAsEmployer,
              max: completedProjectAsEmployer,
            },
          },
        })[0]
      : undefined,
    primaryLanguage,
    corporate,
    isShareholder,
    isRisingStar,
    closed,
  };
}

/**
 * This is different to `location.seed.ts#generateLocationObject` by the country
 * field, even though they return the same `Location` type.
 */
// See `generateProjectViewUserCountry` below
export function getProjectViewUserLocations(
  locationPreset: LocationPreset,
): Location {
  const object = getLocationPresets(locationPreset);
  return generateLocationObject({
    country: generateProjectViewUserCountry(object.countryCode),
    city: object.city,
    fullAddress: object.fullAddress,
    mapCoordinates: object.mapCoordinates,
    vicinity: object.vicinity,
    administrativeArea: object.administrativeArea,
    administrativeAreaCode: object.administrativeAreaCode,
    postalCode: object.postalCode,
  });
}

// ----- Location mixins -----
export function fromSydney(): Pick<GenerateProjectViewUserOptions, 'location'> {
  return {
    location: getProjectViewUserLocations(LocationPreset.SYDNEY),
  };
}

export function fromMelbourne(): Pick<
  GenerateProjectViewUserOptions,
  'location'
> {
  return {
    location: getProjectViewUserLocations(LocationPreset.MELBOURNE),
  };
}

export function fromKualaLumpur(): Pick<
  GenerateProjectViewUserOptions,
  'location'
> {
  return {
    location: getProjectViewUserLocations(LocationPreset.KUALA_LUMPUR),
  };
}

export function fromDhaka(): Pick<GenerateProjectViewUserOptions, 'location'> {
  return {
    location: getProjectViewUserLocations(LocationPreset.DHAKA),
  };
}

export function fromKarachi(): Pick<
  GenerateProjectViewUserOptions,
  'location'
> {
  return {
    location: getProjectViewUserLocations(LocationPreset.KARACHI),
  };
}

export function fromMumbai(): Pick<GenerateProjectViewUserOptions, 'location'> {
  return {
    location: getProjectViewUserLocations(LocationPreset.MUMBAI),
  };
}

export function fromSeattle(): Pick<
  GenerateProjectViewUserOptions,
  'location'
> {
  return {
    location: getProjectViewUserLocations(LocationPreset.SEATTLE),
  };
}

export function fromVaticanCity(): Pick<
  GenerateProjectViewUserOptions,
  'location'
> {
  return {
    location: getProjectViewUserLocations(LocationPreset.VATICAN_CITY),
  };
}

export function fromRome(): Pick<GenerateProjectViewUserOptions, 'location'> {
  return {
    location: getProjectViewUserLocations(LocationPreset.ROME),
  };
}

/**
 * This differs from the `countries` collection since that hits a separate API
 * endpoint. We get this if we hit users_get with the location_details projection.
 *
 * The `countries` collection has a `phoneCode`, while this has a `flagUrl` instead.
 * The `id` and `code` also seem to be uppercase vs lowercase in this.
 */
export function generateProjectViewUserCountry(
  code: LocationCountryCode,
): Country {
  switch (code) {
    case LocationCountryCode.AU:
      return {
        id: 'au',
        code: 'au',
        name: 'Australia',
        flagUrl: '/img/flags/png/au.png',
      };
    case LocationCountryCode.BD:
      return {
        id: 'bd',
        code: 'bd',
        name: 'Bangladesh',
        flagUrl: '/img/flags/png/bd.png',
      };
    case LocationCountryCode.IN:
      return {
        id: 'in',
        code: 'in',
        name: 'India',
        flagUrl: '/img/flags/png/in.png',
      };
    case LocationCountryCode.MY:
      return {
        id: 'my',
        code: 'my',
        name: 'Malaysia',
        flagUrl: '/img/flags/png/my.png',
      };
    case LocationCountryCode.PK:
      return {
        id: 'pk',
        code: 'pk',
        name: 'Pakistan',
        flagUrl: '/img/flags/png/pk.png',
      };
    case LocationCountryCode.US:
      return {
        id: 'us',
        code: 'us',
        name: 'United States',
        flagUrl: '/img/flags/png/us.png',
      };
    case LocationCountryCode.VA:
      return {
        id: 'va',
        code: 'va',
        name: 'Vatican City',
        flagUrl: '/img/flags/png/va.png',
      };
    case LocationCountryCode.IT:
      return {
        id: 'it',
        code: 'it',
        name: 'Italy',
        flagUrl: '/img/flags/png/it.png',
      };

    default:
      assertNever(code);
  }
}

export function generateQualifications(): readonly Qualification[] {
  return [
    {
      id: 2,
      description: 'US English Level 1',
      iconName: 'us_eng_1',
      iconUrl: 'us_eng_1.png',
      level: 1,
      name: 'US English - Level 1',
    },
    {
      id: 3,
      description: 'General Orientation',
      level: 1,
      name: 'General Orientation',
    },
    {
      id: 161,
      description: '"HTML Level 3"',
      iconName: 'html_3',
      iconUrl: 'html_3.png',
      level: 3,
      name: 'HTML - Level 3"',
      scorePercentage: 100,
      type: 'HTML',
      userPercentile: 1,
    },
    {
      id: 69,
      description: 'Freelancer Orientation',
      iconName: 'freelancer_orientation',
      iconUrl: 'freelancer_orientation.png',
    },
    {
      id: 171,
      description: 'JavaScript Level 2"',
      iconName: 'javascript_2',
      iconUrl: 'javascript_2.png',
    },
  ];
}

function generateBadges(): readonly Badge[] {
  return [
    {
      id: 1,
      name: 'The Prospector',
      description: 'Post your first project.',
      timeAwarded: 1_481_700_485_652_000,
      iconUrl: '/img/badges/badge_1.png',
    },
    {
      id: 2,
      name: 'Entrepreneur',
      description: 'Post 50 projects.',
      timeAwarded: 1_559_809_160_278_000,
      iconUrl: '/img/badges/badge_2.png',
    },
    {
      id: 4,
      name: 'Nine-to-Fiver ',
      description: 'Win a project and accept the offer.',
      timeAwarded: 1_484_182_114_224_000,
      iconUrl: '/img/badges/badge_4.png',
    },
    {
      id: 8,
      name: 'The Giver',
      description: 'Award your project to a Freelancer.',
      timeAwarded: 1_487_760_543_899_000,
      iconUrl: '/img/badges/badge_8.png',
    },
    {
      id: 9,
      name: 'The Rainmaker',
      description: 'Award 50 projects.',
      timeAwarded: 1_561_004_447_766_000,
      iconUrl: '/img/badges/badge_9.png',
    },
    {
      id: 13,
      name: 'The Adventurer',
      description: 'Complete your first project.',
      timeAwarded: 1_481_855_462_140_000,
      iconUrl: '/img/badges/badge_13.png',
    },
    {
      id: 16,
      name: 'The Seeker',
      description: 'Bid on 20 projects.',
      timeAwarded: 1_524_706_845_691_000,
      iconUrl: '/img/badges/badge_16.png',
    },
    {
      id: 21,
      name: 'The Speculator',
      description: 'Sponsor 1 bid.',
      timeAwarded: 1_527_676_455_930_000,
      iconUrl: '/img/badges/badge_21.png',
    },
    {
      id: 27,
      name: 'Smart Money',
      description: 'Create 10 Milestones. ',
      timeAwarded: 1_521_076_752_971_000,
      iconUrl: '/img/badges/badge_27.png',
    },
    {
      id: 28,
      name: 'Mover and Shaker',
      description: 'Create 50 Milestones.',
      timeAwarded: 1_526_876_404_272_000,
      iconUrl: '/img/badges/badge_28.png',
    },
    {
      id: 31,
      name: 'All Star',
      description: 'Receive a 5-star rating for a project.',
      timeAwarded: 1_519_105_238_585_000,
      iconUrl: '/img/badges/badge_31.png',
    },
    {
      id: 37,
      name: 'High Roller',
      description:
        'Complete a project worth over $500USD (or equivalent) on Freelancer.com.',
      timeAwarded: 1_519_104_610_971_000,
      iconUrl: '/img/badges/badge_37.png',
    },
    {
      id: 38,
      name: 'Warren Buffet',
      description: 'Complete a project worth over $1000 USD.',
      timeAwarded: 1_527_123_533_046_000,
      iconUrl: '/img/badges/badge_38.png',
    },
    {
      id: 45,
      name: 'Full Service',
      description:
        'Successfully complete a contest, then use the design to complete a project.',
      timeAwarded: 1_549_518_941_897_000,
      iconUrl: '/img/badges/badge_45.png',
    },
    {
      id: 50,
      name: 'The Perfectionist',
      description: 'Edited a bid.',
      timeAwarded: 1_474_565_192_666_000,
      iconUrl: '/img/badges/badge_50.png',
    },
    {
      id: 55,
      name: 'The Collector',
      description: 'Reach a balance of 5,000 credits.',
      timeAwarded: 1_481_700_486_965_000,
      iconUrl: '/img/badges/badge_55.png',
    },
    {
      id: 63,
      name: 'The Deal-Closer',
      description: 'Get awarded 95% of projects (for over 5 projects)',
      timeAwarded: 1_564_018_710_420_000,
      iconUrl: '/img/badges/badge_63.png',
    },
    {
      id: 66,
      name: 'The Regular',
      description: 'Log in every day for 1 week.',
      timeAwarded: 1_496_817_208_353_000,
      iconUrl: '/img/badges/badge_66.png',
    },
    {
      id: 67,
      name: 'Frequent Visitor',
      description: 'Log in in every day for 1 month.',
      timeAwarded: 1_506_410_827_134_000,
      iconUrl: '/img/badges/badge_67.png',
    },
    {
      id: 69,
      name: 'Contest Host',
      description: 'Post a contest.',
      timeAwarded: 1_527_733_001_258_000,
      iconUrl: '/img/badges/badge_69.png',
    },
    {
      id: 75,
      name: 'The Selector',
      description: 'Award 1 contest.',
      timeAwarded: 1_527_733_001_555_000,
      iconUrl: '/img/badges/badge_75.png',
    },
    {
      id: 78,
      name: 'Punch it Live!',
      description: 'Post a Priority Project.',
      timeAwarded: 1_527_831_275_660_000,
      iconUrl: '/img/badges/badge_78.png',
    },
    {
      id: 81,
      name: 'The Investor',
      description: 'Post a featured project.',
      timeAwarded: 1_481_700_486_152_000,
      iconUrl: '/img/badges/badge_81.png',
    },
    {
      id: 88,
      name: 'Insta-Hire',
      description: 'Use Hire Me to employ a Freelancer.',
      timeAwarded: 1_527_733_001_960_000,
      iconUrl: '/img/badges/badge_88.png',
    },
    {
      id: 91,
      name: 'Fast Responder',
      description: 'Bid within 2 minutes of a project being listed.',
      timeAwarded: 1_484_182_192_954_000,
      iconUrl: '/img/badges/badge_91.png',
    },
    {
      id: 93,
      name: 'The Verified',
      description: 'Verify your payment method',
      timeAwarded: 1_481_700_486_653_000,
      iconUrl: '/img/badges/badge_93.png',
    },
    {
      id: 94,
      name: 'Backup plan!',
      description: 'Verify a backup payment method',
      timeAwarded: 1_559_096_645_522_000,
      iconUrl: '/img/badges/badge_94.png',
    },
    {
      id: 95,
      name: 'Quick Pick',
      description: 'Award your project within 24 hours of posting',
      timeAwarded: 1_517_293_840_485_000,
      iconUrl: '/img/badges/badge_95.png',
    },
    {
      id: 96,
      name: 'Speedy Hire',
      description: 'Award 15 projects within 24 hours of posting',
      timeAwarded: 1_559_114_843_750_000,
      iconUrl: '/img/badges/badge_96.png',
    },
    {
      id: 97,
      name: 'Lightning Employment',
      description: 'Award 30 projects within 24 hours of posting',
      timeAwarded: 1_559_190_728_466_000,
      iconUrl: '/img/badges/badge_97.png',
    },
  ];
}

export function generateSkills(): readonly Skill[] {
  return [phpSkill(), graphicDesignSkill()];
}

// ----- Membership types -----
export function generateFreeMembershipPackage({
  skillLimit = 20,
}: {
  readonly skillLimit?: number;
} = {}): MembershipPackage {
  return {
    bidLimit: 8,
    bidRefreshRate: 1,
    id: 1,
    skillChangeLimit: 5,
    skillLimit,
    name: 'free',
    servicePostingLimit: 1,
    timeBidRefreshed: 328_717_000,
  };
}

export function generatePlusMembershipPackage(): MembershipPackage {
  return {
    bidLimit: 100,
    bidRefreshRate: 1,
    id: 1,
    skillChangeLimit: 15,
    skillLimit: 86,
    name: 'plus',
    servicePostingLimit: 10,
    timeBidRefreshed: 328_717_000,
  };
}

export function activeUserSanction(
  duration?: number,
): Pick<GenerateProjectViewUserOptions, 'sanctions'> {
  const now = Date.now();
  return {
    sanctions: [
      {
        startDate: now,
        endDate: now + (duration || 60 * 1000),
        penalty: UserSanctionPenaltyTypeApi.BID_RESTRICTION,
      },
    ],
  };
}

export function userWithReviews({
  count,
  userId,
}: {
  readonly count: number;
  readonly userId: number;
}): Pick<GenerateProjectViewUserOptions, 'reputation'> {
  return {
    reputation: generateReputationObject({
      reputationOptions: {
        total: { min: count, max: count },
        reviewsPercentage: { min: 1, max: 1 },
      },
      userIds: [userId],
    })[0], // FIXME: T267853 - , use singular
  };
}

// A rookie user is a user who has no earnings
export function rookieUser({
  userId,
}: {
  readonly userId: number;
}): Pick<GenerateProjectViewUserOptions, 'reputation'> {
  return {
    reputation: generateReputationObject({
      userIds: [userId],
      earningsScore: { min: 0, max: 0 },
    })[0],
  };
}

// A non-rookie user is a user who has earnings
export function nonRookieUser({
  userId,
}: {
  readonly userId: number;
}): Pick<GenerateProjectViewUserOptions, 'reputation'> {
  return {
    reputation: generateReputationObject({
      userIds: [userId],
      earningsScore: { min: 1, max: 1 },
    })[0],
  };
}

export function rookieUsers({
  userIds,
}: {
  readonly userIds: readonly number[];
}): Pick<GenerateProjectViewUsersOptions, 'reputation'> {
  return {
    reputation: generateReputationObject({
      earningsScore: { min: 0, max: 0 },
      userIds,
    }),
  };
}

// Keep the same as `transformIsNewFreelancer()` in project-view-users.transformer.ts
function transformIsNewFreelancer(reputation: Reputation): boolean {
  return (
    !reputation.entireHistory.reviews &&
    !reputation.earningsScore &&
    !reputation.entireHistory.all
  );
}

export function withReputation(): Pick<
  GenerateProjectViewUserOptions,
  'reputation'
> {
  return {
    reputation: {
      entireHistory: generateReputationDataObject(defaultReputations),
      last3Months: generateReputationDataObject(defaultReputations),
      last12Months: generateReputationDataObject(defaultReputations),
      earningsScore: 5,
    },
  };
}
