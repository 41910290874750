import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
} from '@angular/core';
import { ThemeService } from '@freelancer/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom, Subscription } from 'rxjs';
import { Assets } from '../assets';
import { FreelancerBreakpoints } from '../breakpoints';
import type { BackgroundColourType } from './logo.types';
import { BackgroundColor, LogoSize } from './logo.types';

@UntilDestroy({ className: 'LogoComponent' })
@Component({
  selector: 'fl-logo',
  template: `
    <picture>
      <source
        *ngIf="logoSrcDesktop"
        [media]="FreelancerBreakpoints.DESKTOP_SMALL"
        [srcset]="logoSrcDesktop"
      />
      <source
        *ngIf="logoSrcTablet"
        [media]="FreelancerBreakpoints.TABLET"
        [srcset]="logoSrcTablet"
      />
      <source
        *ngIf="logoSrc"
        [srcset]="logoSrc"
      />
      <img
        class="LogoImg"
        alt="Freelancer logo"
        i18n-alt="Freelancer logo alt text"
        [src]="logoSrc"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.id]="id"
      />
    </picture>
  `,
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit, OnChanges, OnDestroy {
  FreelancerBreakpoints = FreelancerBreakpoints;
  LogoSize = LogoSize;

  private assets = inject(Assets);
  private themeService = inject(ThemeService);
  private cdr = inject(ChangeDetectorRef);

  @Input() size = LogoSize.MID;
  @Input() sizeTablet?: LogoSize;
  @Input() sizeDesktop?: LogoSize;
  @Input() backgroundColor: BackgroundColor | BackgroundColourType =
    BackgroundColor.LIGHT;
  @Input() id: string;

  logoSrc: string;
  logoSrcTablet?: string;
  logoSrcDesktop?: string;
  calculatedBackgroundColor?: BackgroundColor | BackgroundColourType;

  private subscription = new Subscription();

  async ngOnInit(): Promise<void> {
    this.subscription.add(
      this.themeService.getTheme().subscribe(async () => {
        await this.generateIconColor();
        this.setLogoSize();
        this.setLogoSizeTablet();
        this.setLogoSizeDesktop();
        this.cdr.markForCheck();
      }),
    );
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if ('backgroundColor' in changes) {
      await this.generateIconColor();
    }

    if ('size' in changes || 'backgroundColor' in changes) {
      this.setLogoSize();
    }

    if ('sizeTablet' in changes || 'backgroundColor' in changes) {
      this.setLogoSizeTablet();
    }

    if ('sizeDesktop' in changes || 'backgroundColor' in changes) {
      this.setLogoSizeDesktop();
    }
  }

  private async generateIconColor(): Promise<void> {
    // If the background color is not set to theme, we don't need to calculate the color of the icon.
    if (this.backgroundColor !== BackgroundColor.THEME) {
      this.calculatedBackgroundColor = this.backgroundColor;
      return;
    }

    const theme = await firstValueFrom(
      this.themeService.getTheme().pipe(untilDestroyed(this)),
    );

    if (theme === 'dark') {
      this.calculatedBackgroundColor = BackgroundColor.DARK;
    } else if (theme === 'light') {
      this.calculatedBackgroundColor = BackgroundColor.LIGHT;
    }
  }

  private generateLogoSize(size: LogoSize | undefined): string | undefined {
    switch (size) {
      case LogoSize.ICON:
      case LogoSize.MINI:
        return this.calculatedBackgroundColor === BackgroundColor.LIGHT
          ? this.assets.getUrl('freelancer-logo-icon.svg')
          : this.assets.getUrl('freelancer-logo-icon-light.svg');

      case LogoSize.SMALL:
      case LogoSize.MID:
        return this.calculatedBackgroundColor === BackgroundColor.LIGHT
          ? this.assets.getUrl('freelancer-logo.svg')
          : this.assets.getUrl('freelancer-logo-light.svg');

      default:
        return undefined;
    }
  }

  private setLogoSize(): void {
    this.logoSrc =
      this.generateLogoSize(this.size) ||
      this.assets.getUrl('freelancer-logo.svg');
  }

  private setLogoSizeTablet(): void {
    this.logoSrcTablet = this.generateLogoSize(this.sizeTablet);
  }

  private setLogoSizeDesktop(): void {
    this.logoSrcDesktop = this.generateLogoSize(this.sizeDesktop);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
