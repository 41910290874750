export enum LinkColor {
  INHERIT = 'inherit',
  LIGHT = 'light',
  MID = 'mid',
  DARK = 'dark',
  DEFAULT = 'default',
  PRIMARY = 'primary',
  FOREGROUND = 'foreground',
}

export enum LinkHoverColor {
  INHERIT = 'inherit',
  LIGHT = 'light',
  DEFAULT = 'default',
  PRIMARY = 'primary',
}

export enum LinkUnderline {
  ALWAYS = 'always',
  NEVER = 'never',
  HOVER = 'hover',
}

export enum LinkWeight {
  INHERIT = 'inherit',
  BOLD = 'bold',
  MEDIUM = 'medium',
}

export enum LinkIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface QueryParams {
  [k: string]: any;
}

export enum QueryParamsHandling {
  MERGE = 'merge',
  PRESERVE = 'preserve',
  NONE = '',
}

export enum LinkType {
  MAIL = 'mail',
  TEL = 'tel',
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export enum LinkOutline {
  INHERIT = 'inherit',
}
