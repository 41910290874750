import { CountryCode } from '../countries';
import type { Timezone } from './timezones.model';

export function generateTimezoneObjects(): readonly Timezone[] {
  return Object.values(timezones);
}

/**
 * Dictionary of timezones with the id from database as key.
 */
export const timezones: {
  readonly [id: number]: Timezone;
} = Object.fromEntries(
  [
    {
      id: 56,
      country: CountryCode.AR,
      timezone: 'America/Argentina/Buenos_Aires',
      offset: '-0300',
    },
    {
      id: 123,
      country: CountryCode.US,
      timezone: 'America/Phoenix',
      offset: '-0700',
    },
    {
      id: 145,
      country: CountryCode.CA,
      timezone: 'America/Vancouver',
      offset: '-0700',
    },
    {
      id: 192,
      country: CountryCode.PH,
      timezone: 'Asia/Manila',
      offset: '+0800',
    },
    {
      id: 422,
      country: CountryCode.AU,
      timezone: 'Australia/Sydney',
      offset: '+1000',
    },
  ].map(timezone => [timezone.id, timezone]),
);

export enum TimezonePreset {
  PHOENIX = 'Vancouver',
  SYDNEY = 'Sydney',
}

export const timezonePresets: {
  [key in TimezonePreset]: Timezone;
} = {
  [TimezonePreset.PHOENIX]: { ...timezones[123] },
  [TimezonePreset.SYDNEY]: { ...timezones[422] },
};
