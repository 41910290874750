import { isPlatformServer, Location as NgLocation } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Applications, APP_NAME } from '@freelancer/config';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import type { Subscription } from 'rxjs';
import { Location } from './location.service';

interface LocationUrl {
  pathname: string;
  search: string;
  hash: string;
}

@Component({
  selector: 'fl-location',
  template: ` <ng-container></ng-container> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// This component pushes new locations to the Location service and must be
// imported by the root app component, as this can't be done from services in
// Angular
export class LocationComponent implements OnInit, OnDestroy {
  private updateLocationSubscription?: Subscription;

  constructor(
    private router: Router,
    private location: Location,
    private ngLocation: NgLocation,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_NAME) private appName: Applications,
    @Optional() @Inject(REQUEST) private request: Request,
  ) {}

  ngOnInit(): void {
    this.location.markInitialState();
    this.updateLocationSubscription = this.router.events.subscribe(event => {
      // push new location to Location service

      if (event instanceof NavigationEnd) {
        // Removes the /admin from the request url because event.url doesn't
        // have the /admin prefix.
        const requestUrl =
          this.appName === Applications.ADMIN
            ? this.request?.url.replace('/admin', '')
            : this.request?.url;
        // When on the server, send 302s on internal redirects
        if (
          isPlatformServer(this.platformId) &&
          event.url !== '/internal/404' &&
          event.url !== '/internal/blank' &&
          event.url !== requestUrl
        ) {
          // Get initial user request URL parts
          const userRequestUrl = this.getUrlParts(requestUrl);

          // Get server platform location URL parts
          const platformLocation = this.getUrlParts(
            (this.ngLocation as any)?._locationStrategy.path(true),
          );
          const platformUrl: LocationUrl = {
            pathname: platformLocation.pathname,
            search: platformLocation.search,
            hash: platformLocation.search,
          };

          // Don't send HTTP redirects for navigations with `skipLocationChange`
          // as the redirect location isn't meant to be visible by end-users
          const currentNavigation = this.router.getCurrentNavigation();
          if (currentNavigation?.extras?.skipLocationChange) {
            // Check if initial requested URL and final displayed URLs are
            // different and redirect to the final one with 302 if so.
            if (userRequestUrl.pathname !== platformUrl.pathname) {
              this.location.navigateByUrl(
                `${platformUrl.pathname}${platformUrl.search}`,
              );
              return;
            }
          } else {
            // We return 301 when stripping trailing slashes.
            // For the comparison to work, we need to ignore query parameters.
            const eventUrl = this.removeUrlParams(event.url);
            const requestPath = this.removeUrlParams(requestUrl);
            this.location.navigateByUrl(event.url, {
              isPermanentRedirect: `${eventUrl}/` === requestPath,
            });
            return;
          }
        }
        // When on the client, update the Location state
        const { pathname, hash, search } = this.getUrlParts(event.url);
        this.location._setLocation(pathname, hash, search);
      }
    });
  }

  ngOnDestroy(): void {
    this.updateLocationSubscription?.unsubscribe();
  }

  private removeUrlParams(url: string): string {
    return url.split('?')[0];
  }

  private getUrlParts(url: string): LocationUrl {
    // NOTE: the base is a dummy only to properly parse the url,
    // but we don't need it in the _setLocation call
    const urlObject = new URL(url, 'https://freelancer.com');
    return (({ pathname, search, hash }) => ({ pathname, search, hash }))(
      urlObject,
    );
  }
}
