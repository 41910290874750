import { isPlatformServer } from '@angular/common';
import type {
  HttpErrorResponse,
  HttpEvent,
  HttpParameterCodec,
} from '@angular/common/http';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import {
  ErrorHandler,
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import type { AuthServiceInterface } from '@freelancer/auth/interface';
import { Applications, APP_NAME } from '@freelancer/config';
import { Localization } from '@freelancer/localization';
import { Location } from '@freelancer/location';
import { Pwa } from '@freelancer/pwa';
import { ToastAlertService } from '@freelancer/ui/toast-alert';
import { isDefined } from '@freelancer/utils';
import { ErrorCodeApi } from 'api-typings/errors/errors';
import type { Observable } from 'rxjs';
import { of, combineLatest, from } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import {
  FREELANCER_HTTP_ABTEST_OVERRIDES_PROVIDER,
  FREELANCER_HTTP_AUTH_PROVIDERS,
  FREELANCER_HTTP_CONFIG,
  FREELANCER_HTTP_TRACKING_PROVIDER,
} from './freelancer-http.config';
import type {
  ErrorResponseData,
  HttpAdapter,
  ResponseData,
} from './freelancer-http.interface';
import {
  FreelancerHttpABTestOverridesInterface,
  FreelancerHttpConfig,
  FreelancerHttpTrackingInterface,
} from './freelancer-http.interface';

/*
 * Transforms response data given transformer callback function
 * @param responseData Response data
 * @param transform Transformer callback function
 */
export function transformResponseData<T, U, E>(
  responseData: ResponseData<T, E>,
  transform: (obj: T) => U,
): ResponseData<U, E> {
  if (responseData.status === 'error') {
    return responseData;
  }

  return {
    ...responseData,
    result: transform(responseData.result),
  };
}

export type RequestParamsObjectArrayValue = readonly (
  | string
  | number
  | boolean
  | RequestParamsObject
  | undefined
)[];

export enum FreelancerHttpService {
  AJAX_API,
  API,
  AUTH,
  FILE_SERVICE,
}

// Headers for tracking app info.
export const FREELANCER_APP_BUILD_TIMESTAMP_HEADER =
  'freelancer-app-build-timestamp';
export const FREELANCER_APP_IS_INSTALLED_HEADER = 'freelancer-app-is-installed';
export const FREELANCER_APP_IS_NATIVE_HEADER = 'freelancer-app-is-native';
export const FREELANCER_APP_LOCALE_HEADER = 'freelancer-app-locale';
export const FREELANCER_APP_NAME_HEADER = 'freelancer-app-name';
export const FREELANCER_APP_NATIVE_DEVICE_INFO =
  'freelancer-app-native-device-info';
export const FREELANCER_APP_PLATFORM_HEADER = 'freelancer-app-platform';
export const FREELANCER_APP_VERSION_HEADER = 'freelancer-app-version';

export interface RequestParamsObject {
  [k: string]:
    | string
    | number
    | boolean
    | RequestParamsObjectArrayValue
    | RequestParamsObject
    | undefined;
}

export interface RawSuccessResponseData<T> {
  status: 'success';
  result: T;
  request_id?: string;
}

/**
 * Request options that serves as an alias for request options for HttpClient
 * methods (because they don't export them) plus a couple .
 * These are limited to currently the commonly used options for a direct
 * HTTP request. If you're missing anything that HttpClient has, please check
 * the docs on HttpClient requests and see if they are shared between all CRUD
 * methods first, then you're free to add it in.
 */
export interface RequestOptions<E> {
  // @deprecated, use service
  isGaf?: boolean;
  service?: FreelancerHttpService;
  serializeBody?: boolean;
  /**
   * A list of errors "expected" as part of the normal site flow.
   * These errors won't be sent to the `ErrorHandler` so they won't log extra details
   * and they won't be sent as part of our backend error logging.
   */
  errorWhitelist?: E[];
  params?: RequestParamsObject | HttpParams;
  reportProgress?: boolean;
  headers?: HttpHeaders;
  withCredentials?: boolean;
}

/*
 * This removes the special parameter encoding done by Angular for apparently
 * no reason, see https://github.com/angular/angular/issues/18261.
 * FIXME: remove that when Angular has fixed it.
 */
export class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({
  providedIn: 'root',
})
export class FreelancerHttp implements HttpAdapter {
  private readonly requestParams = {
    // Strip out null values on the response.
    compact: 'true',
    // Receive new error format
    new_errors: 'true',
    // Receive integer Pool IDs in the response. FIXME: Cleanup T220848
    new_pools: 'true',
  };

  private isCheckingAuthState: boolean;

  constructor(
    private errorHandler: ErrorHandler,
    private http: HttpClient,
    private location: Location,
    private pwa: Pwa,
    private router: Router,
    private toastAlertService: ToastAlertService,
    @Inject(FREELANCER_HTTP_AUTH_PROVIDERS)
    private authProviders: readonly AuthServiceInterface[],
    @Inject(FREELANCER_HTTP_CONFIG)
    private freelancerHttpConfig: FreelancerHttpConfig,
    @Inject(APP_NAME) private appName: Applications,
    @Inject(PLATFORM_ID) private platformId: Object,
    private localization: Localization,
    @Inject(FREELANCER_HTTP_TRACKING_PROVIDER)
    @Optional()
    private tracking?: FreelancerHttpTrackingInterface,
    @Inject(FREELANCER_HTTP_ABTEST_OVERRIDES_PROVIDER)
    @Optional()
    private abTestOverridesHeader?: FreelancerHttpABTestOverridesInterface,
  ) {}

  /**
   * Utility function to merge the Auth headers with the request headers.
   * @param extraHeaders HttpHeaders from Auth
   * @param requestHeaders HttpHeaders specified on the request options.
   */
  private mergeExtraHeadersWithRequestHeaders(
    extraHeadersArray: readonly HttpHeaders[],
    requestHeaders?: HttpHeaders,
  ): HttpHeaders {
    let extraHeaders = new HttpHeaders();
    extraHeadersArray.forEach(headers =>
      headers.keys().forEach(key => {
        const values = headers.getAll(key);
        if (values) {
          extraHeaders = extraHeaders.append(key, values);
        }
      }),
    );

    if (!requestHeaders) {
      return extraHeaders;
    }

    return extraHeaders.keys().reduce((obj, key) => {
      const values = extraHeaders.getAll(key);
      return values ? obj.append(key, values) : obj;
    }, requestHeaders);
  }

  /**
   * Get the base URL to use based on the resource you want to access
   * @param service FreelancerHttpService
   */
  getBaseServiceUrl(
    service: FreelancerHttpService = FreelancerHttpService.API,
  ): string {
    switch (service) {
      case FreelancerHttpService.API:
        return this.freelancerHttpConfig.apiBaseUrl;
      case FreelancerHttpService.AJAX_API:
        return this.freelancerHttpConfig.ajaxBaseUrl;
      case FreelancerHttpService.AUTH:
        return this.freelancerHttpConfig.authBaseUrl;
      case FreelancerHttpService.FILE_SERVICE:
        return this.freelancerHttpConfig.fileServiceBaseUrl;
      default:
        return this.freelancerHttpConfig.apiBaseUrl;
    }
  }

  /**
   * Format response body to the interface that we want to return.
   * For now it's only adding a status code into the response body.
   * @param response The HttpResponse object.
   */
  private formatResponseBody<T, E>(
    response: HttpResponse<RawSuccessResponseData<T>>,
  ): ResponseData<T, E | 'UNKNOWN_ERROR'> {
    if (response.body === null) {
      console.error('No response body', response);
      return {
        status: 'error',
        errorCode: 'UNKNOWN_ERROR',
      };
    }

    if (response.body.status !== 'success') {
      console.error('Malformed backend response', response.body);
      return {
        status: 'error',
        errorCode: 'UNKNOWN_ERROR',
      };
    }

    return {
      status: response.body.status,
      result: response.body.result,
      requestId: response.body.request_id,
    };
  }

  private formatErrorBody<E>(error: HttpErrorResponse): ErrorResponseData<E> {
    // If an error is found, but the request ID isn't present, there is likely
    // an issue with the network.
    if (
      error.error &&
      !error.error.request_id &&
      error.error.error === undefined
    ) {
      return {
        status: 'error',
        errorCode: 'NETWORK_ERROR',
      };
    }

    if (error.error && error.error.error !== undefined) {
      return {
        status: 'error',
        errorCode: error.error.error.code,
        requestId: error.error.request_id,
      };
    }

    // Another type of backend error occured, e.g. External LB or Varnish.
    return {
      status: 'error',
      errorCode: 'UNKNOWN_ERROR',
    };
  }

  /**
   * Handles error responses from the API and returns a formatted body
   * in a form appropriate for the request options
   *
   * Reports to the global error handler depending on whitelist logic.
   * @param error The HttpErrorResponse object
   * @param options The request options
   */
  private handleError<T = any, E = any>(
    error: HttpErrorResponse,
    options?: RequestOptions<E | 'UNKNOWN_ERROR' | 'NETWORK_ERROR'> & {
      reportProgress?: false;
    },
  ): Observable<ResponseData<T, E | 'UNKNOWN_ERROR'>>;
  private handleError<T = any, E = any>(
    error: HttpErrorResponse,
    options?: RequestOptions<E | 'UNKNOWN_ERROR' | 'NETWORK_ERROR'> & {
      reportProgress: true;
    },
  ): Observable<HttpEvent<ResponseData<T, E | 'UNKNOWN_ERROR'>>>;
  private handleError<T = any, E = any>(
    error: HttpErrorResponse,
    options: RequestOptions<E | 'UNKNOWN_ERROR' | 'NETWORK_ERROR'> = {},
  ):
    | Observable<ResponseData<T, E | 'UNKNOWN_ERROR'>>
    | Observable<HttpEvent<ResponseData<T, E | 'UNKNOWN_ERROR'>>> {
    let baseObservable$ = of(true);
    const formattedBody = this.formatErrorBody<E | 'UNKNOWN_ERROR'>(error);
    if (
      !options.errorWhitelist ||
      !options.errorWhitelist.includes(formattedBody.errorCode)
    ) {
      // send error to errorHandler
      this.errorHandler.handleError(error);

      if (error.status === 401 && !this.isCheckingAuthState) {
        this.isCheckingAuthState = true;
        baseObservable$ = combineLatest(
          this.authProviders.map(auth => auth.isLoggedIn()),
        ).pipe(
          take(1),
          switchMap(loginStatuses =>
            // if we are logged in
            loginStatuses.includes(true)
              ? // check our token validity
                this.get('info/', {
                  service: FreelancerHttpService.AUTH,
                  withCredentials: true,
                  errorWhitelist: [ErrorCodeApi.UNAUTHORIZED],
                }).pipe(take(1))
              : of(undefined),
          ),
          switchMap(infoResult => {
            this.isCheckingAuthState = false;
            if (infoResult && infoResult.status === 'error') {
              // If the token is invalid, clear it and force a page reload
              this.authProviders.forEach(auth => auth.deleteSession());
              return from(
                this.location.navigateByUrl(this.router.url, {
                  onSameUrlNavigation: 'reload',
                }),
              ).pipe(
                tap(() => {
                  this.toastAlertService.open('login-session-expired');
                }),
              );
            }
            return of(true);
          }),
        );
      } else if (error.status === 403) {
        // Check if 403 is because of IP blocking, or something else
        baseObservable$ = this.http
          .get<boolean>(
            `${this.getBaseServiceUrl(FreelancerHttpService.API)}/ping`,
            {
              params: this._serialize(options.params),
            },
          )
          .pipe(
            take(1),
            map(() => {
              return true;
            }),
            catchError(err => {
              // IP is blocked, navigate to unblock-ip page
              if (err.status === 403) {
                this.router.navigate(
                  [
                    '/internal/ip-blacklist-unblock',
                    {
                      request_id: error.error.request_id,
                      recaptcha_key: error.error.recaptcha_key,
                      back_url: this.router.url,
                    },
                  ],
                  {
                    skipLocationChange: true,
                  },
                );
              }
              return of(true);
            }),
          );
      }
    }
    return options.reportProgress
      ? baseObservable$.pipe(
          map(
            () =>
              new HttpResponse<ErrorResponseData<E | 'UNKNOWN_ERROR'>>({
                body: this.formatErrorBody(error),
              }),
          ),
        )
      : baseObservable$.pipe(map(() => formattedBody));
  }

  /**
   * Helper function to convert an object to HttpParams, which is conformant
   * to the `application/x-www-form-urlencoded` MIME type.
   *
   * `undefined` values and empty arrays are not serialized.
   *  Non-empty array values have `[]` appended to their keys.
   *
   * @param paramsObject The object to convert. Limited to string, number,
   * boolean primitives as values, array containing those primitives, or
   * nested objects. This means NO nested arrays, e.g. [1, [2, 3]]
   */
  _serialize(paramsObject: RequestParamsObject | HttpParams = {}): HttpParams {
    if (paramsObject instanceof HttpParams) {
      return paramsObject;
    }

    return Object.entries(paramsObject).reduce<HttpParams>(
      (httpParams, [key, value]) => {
        if (Array.isArray(value)) {
          return (value as RequestParamsObjectArrayValue)
            .filter(isDefined)
            .reduce((innerParams, arrayItem) => {
              // FIXME: T267853 - This is not allowed according to the type definition
              // of RequestParamsObject, so this branch is unlikely to be taken
              if (Array.isArray(arrayItem)) {
                // Make sure that batching is handled here. Break all & into more
                // params additions.
                return String(arrayItem as RequestParamsObjectArrayValue)
                  .split('&')
                  .reduce(
                    (innerInnerParams, arrayBatchItem) =>
                      innerInnerParams.append(
                        `${key}[]`,
                        typeof arrayBatchItem !== 'string'
                          ? JSON.stringify(arrayBatchItem)
                          : arrayBatchItem,
                      ),
                    innerParams,
                  );
              }

              // Stringify the array item because we need a string and if it's an
              // inner object just stringify to JSON.
              return innerParams.append(
                `${key}[]`,
                typeof arrayItem !== 'string'
                  ? JSON.stringify(arrayItem)
                  : arrayItem,
              );
            }, httpParams);
        }

        // Our REST API backend expects arrays foo=[1,2] to be encoded as
        // foo[]=1&foo[]=2 (but only for form-encoded payloads, see T74444)
        if (value !== undefined) {
          // Don't stringify the value if it's a string, otherwise you'll end up
          // with quotation marks surrounding the string (`"foo"` instead of `foo`).
          return httpParams.append(
            key,
            typeof value !== 'string' ? JSON.stringify(value) : value,
          );
        }

        return httpParams;
      },
      new HttpParams({ encoder: new CustomEncoder() }),
    );
  }

  /**
   * Construct a GET request to the backend.
   * @param endpoint The endpoint
   * @param options Request options
   */
  get<T = any, E = any>(
    endpoint: string,
    options?: RequestOptions<E | 'UNKNOWN_ERROR'> & { reportProgress?: false },
  ): Observable<ResponseData<T, E | 'UNKNOWN_ERROR'>>;
  get<T = any, E = any>(
    endpoint: string,
    options?: RequestOptions<E | 'UNKNOWN_ERROR'> & { reportProgress: true },
  ): Observable<HttpEvent<ResponseData<T, E | 'UNKNOWN_ERROR'>>>;
  get<T = any, E = any>(
    endpoint: string,
    options: RequestOptions<E | 'UNKNOWN_ERROR'> = {},
  ):
    | Observable<ResponseData<T, 'UNKNOWN_ERROR' | E>>
    | Observable<HttpEvent<ResponseData<T, 'UNKNOWN_ERROR' | E>>> {
    const service = options.isGaf
      ? FreelancerHttpService.AJAX_API
      : options.service || FreelancerHttpService.API;
    const baseUrl = this.getBaseServiceUrl(service);

    // Add webapp=1 for tracking in the backend to API requests
    const requestParams = options.isGaf
      ? { ...options.params, ...this.requestParams }
      : { ...options.params, webapp: 1, ...this.requestParams };

    if (options.reportProgress) {
      return this.getExtraHeaders().pipe(
        take(1),
        map(extraHeaders => ({
          endpoint,
          extraHeaders,
        })),
        switchMap(({ endpoint: requestEndpoint, extraHeaders }) =>
          this.http.get<RawSuccessResponseData<T>>(
            `${baseUrl}/${requestEndpoint}`,
            {
              reportProgress: options.reportProgress,
              observe: 'events',
              params: this._serialize(requestParams),
              withCredentials: options.withCredentials,
              headers: this.mergeExtraHeadersWithRequestHeaders(
                extraHeaders,
                options.headers,
              ),
            },
          ),
        ),
        map(event => {
          if (event instanceof HttpResponse) {
            return event.clone({
              body: this.formatResponseBody<T, E>(event),
            });
          }

          return event;
        }),
        catchError((error: HttpErrorResponse) =>
          this.handleError(error, { ...options, reportProgress: true }),
        ),
      );
    }

    return this.getExtraHeaders().pipe(
      take(1),
      map(extraHeaders => ({
        endpoint,
        extraHeaders,
      })),
      switchMap(({ endpoint: requestEndpoint, extraHeaders }) =>
        this.http.get<RawSuccessResponseData<T>>(
          `${baseUrl}/${requestEndpoint}`,
          {
            reportProgress: options.reportProgress,
            observe: 'response',
            params: this._serialize(requestParams),
            withCredentials: options.withCredentials,
            headers: this.mergeExtraHeadersWithRequestHeaders(
              extraHeaders,
              options.headers,
            ),
          },
        ),
      ),
      map(response => this.formatResponseBody<T, E>(response)),
      catchError((error: HttpErrorResponse) =>
        this.handleError(error, { ...options, reportProgress: false }),
      ),
    );
  }

  /**
   * Construct a POST request to the backend
   * @param endpoint The endpoint
   * @param body Request body
   * @param options Request options
   */
  post<T = any, E = any>(
    endpoint: string,
    body: any | null,
    options?: RequestOptions<E | 'UNKNOWN_ERROR'> & { reportProgress?: false },
  ): Observable<ResponseData<T, E | 'UNKNOWN_ERROR'>>;
  post<T = any, E = any>(
    endpoint: string,
    body: any | null,
    options?: RequestOptions<E | 'UNKNOWN_ERROR'> & { reportProgress: true },
  ): Observable<HttpEvent<ResponseData<T, E | 'UNKNOWN_ERROR'>>>;
  post<T = any, E = any>(
    endpoint: string,
    body: any | null,
    options: RequestOptions<E | 'UNKNOWN_ERROR'> = {},
  ):
    | Observable<ResponseData<T, E | 'UNKNOWN_ERROR'>>
    | Observable<HttpEvent<ResponseData<T, E | 'UNKNOWN_ERROR'>>> {
    const service = options.isGaf
      ? FreelancerHttpService.AJAX_API
      : options.service || FreelancerHttpService.API;
    const baseUrl = this.getBaseServiceUrl(service);

    const requestBody = options.serializeBody ? this._serialize(body) : body;

    const requestParams = { ...options.params, ...this.requestParams };

    if (options.reportProgress) {
      return this.getExtraHeaders().pipe(
        take(1),
        map(extraHeaders => ({
          endpoint,
          extraHeaders,
        })),
        switchMap(({ endpoint: requestEndpoint, extraHeaders }) =>
          this.http.post<RawSuccessResponseData<T>>(
            `${baseUrl}/${requestEndpoint}`,
            requestBody,
            {
              reportProgress: options.reportProgress,
              observe: 'events',
              withCredentials: options.withCredentials,
              headers: this.mergeExtraHeadersWithRequestHeaders(
                extraHeaders,
                options.headers,
              ),
              params: this._serialize(requestParams),
            },
          ),
        ),
        map(event => {
          if (event instanceof HttpResponse) {
            return event.clone({
              body: this.formatResponseBody<T, E>(event),
            });
          }

          return event;
        }),
        catchError((error: HttpErrorResponse) =>
          this.handleError(error, { ...options, reportProgress: true }),
        ),
      );
    }

    return this.getExtraHeaders().pipe(
      take(1),
      map(extraHeaders => ({
        endpoint,
        extraHeaders,
      })),
      switchMap(({ endpoint: requestEndpoint, extraHeaders }) =>
        this.http.post<RawSuccessResponseData<T>>(
          `${baseUrl}/${requestEndpoint}`,
          requestBody,
          {
            reportProgress: options.reportProgress,
            observe: 'response',
            withCredentials: options.withCredentials,
            headers: this.mergeExtraHeadersWithRequestHeaders(
              extraHeaders,
              options.headers,
            ),
            params: this._serialize(requestParams),
          },
        ),
      ),
      map(event => this.formatResponseBody<T, E>(event)),
      catchError((error: HttpErrorResponse) =>
        this.handleError(error, { ...options, reportProgress: false }),
      ),
    );
  }

  /**
   * Construct a PUT request to the backend
   * @param endpoint The endpoint
   * @param body Request body
   * @param options Request options
   */
  put<T = any, E = any>(
    endpoint: string,
    body: any | null,
    options?: RequestOptions<E | 'UNKNOWN_ERROR'> & { reportProgress?: false },
  ): Observable<ResponseData<T, E | 'UNKNOWN_ERROR'>>;
  put<T = any, E = any>(
    endpoint: string,
    body: any | null,
    options?: RequestOptions<E | 'UNKNOWN_ERROR'> & { reportProgress: true },
  ): Observable<HttpEvent<ResponseData<T, E | 'UNKNOWN_ERROR'>>>;
  put<T = any, E = any>(
    endpoint: string,
    body: any | null,
    options: RequestOptions<E | 'UNKNOWN_ERROR'> = {},
  ):
    | Observable<ResponseData<T, E | 'UNKNOWN_ERROR'>>
    | Observable<HttpEvent<ResponseData<T, E | 'UNKNOWN_ERROR'>>> {
    const service = options.isGaf
      ? FreelancerHttpService.AJAX_API
      : options.service || FreelancerHttpService.API;
    const baseUrl = this.getBaseServiceUrl(service);

    const requestBody = options.serializeBody ? this._serialize(body) : body;

    const requestParams = { ...options.params, ...this.requestParams };

    if (options.reportProgress) {
      return this.getExtraHeaders().pipe(
        take(1),
        map(extraHeaders => ({
          endpoint,
          extraHeaders,
        })),
        switchMap(({ endpoint: requestEndpoint, extraHeaders }) =>
          this.http.put<RawSuccessResponseData<T>>(
            `${baseUrl}/${requestEndpoint}`,
            requestBody,
            // FIXME: T267853 - <any> is needed because of
            // https://github.com/angular/angular/issues/23600
            {
              reportProgress: options.reportProgress,
              observe: 'events',
              withCredentials: options.withCredentials,
              headers: this.mergeExtraHeadersWithRequestHeaders(
                extraHeaders,
                options.headers,
              ),
              params: this._serialize(requestParams),
            } as any,
          ),
        ),
        map(event => {
          if (event instanceof HttpResponse) {
            return event.clone({
              body: this.formatResponseBody<T, E>(event),
            });
          }

          return event;
        }),
        catchError((error: HttpErrorResponse) =>
          this.handleError(error, { ...options, reportProgress: true }),
        ),
      );
    }

    return this.getExtraHeaders().pipe(
      take(1),
      map(extraHeaders => ({
        endpoint,
        extraHeaders,
      })),
      switchMap(({ endpoint: requestEndpoint, extraHeaders }) =>
        this.http.put<RawSuccessResponseData<T>>(
          `${baseUrl}/${requestEndpoint}`,
          requestBody,
          {
            reportProgress: options.reportProgress,
            observe: 'response',
            withCredentials: options.withCredentials,
            headers: this.mergeExtraHeadersWithRequestHeaders(
              extraHeaders,
              options.headers,
            ),
            params: this._serialize(requestParams),
          },
        ),
      ),
      map(event => this.formatResponseBody<T, E>(event)),
      catchError((error: HttpErrorResponse) =>
        this.handleError(error, { ...options, reportProgress: false }),
      ),
    );
  }

  /**
   * Construct a DELETE request to the backend
   * @param endpoint The endpoint
   * @param options Request options
   */
  delete<T = any, E = any>(
    endpoint: string,
    options?: RequestOptions<E | 'UNKNOWN_ERROR'> & { reportProgress?: false },
  ): Observable<ResponseData<T, E | 'UNKNOWN_ERROR'>>;
  delete<T = any, E = any>(
    endpoint: string,
    options?: RequestOptions<E | 'UNKNOWN_ERROR'> & { reportProgress: true },
  ): Observable<HttpEvent<ResponseData<T, E | 'UNKNOWN_ERROR'>>>;
  delete<T = any, E = any>(
    endpoint: string,
    options: RequestOptions<E | 'UNKNOWN_ERROR'> = {},
  ):
    | Observable<ResponseData<T, E | 'UNKNOWN_ERROR'>>
    | Observable<HttpEvent<ResponseData<T, E | 'UNKNOWN_ERROR'>>> {
    const service = options.isGaf
      ? FreelancerHttpService.AJAX_API
      : options.service || FreelancerHttpService.API;
    const baseUrl = this.getBaseServiceUrl(service);

    // Add webapp=1 for tracking in the backend to API requests
    const requestParams = options.isGaf
      ? { ...options.params, ...this.requestParams }
      : { ...options.params, webapp: 1, ...this.requestParams };

    if (options.reportProgress) {
      return this.getExtraHeaders().pipe(
        take(1),
        map(extraHeaders => ({
          endpoint,
          extraHeaders,
        })),
        switchMap(({ endpoint: requestEndpoint, extraHeaders }) =>
          this.http.delete<RawSuccessResponseData<T>>(
            `${baseUrl}/${requestEndpoint}`,
            {
              reportProgress: options.reportProgress,
              observe: 'events',
              withCredentials: options.withCredentials,
              headers: this.mergeExtraHeadersWithRequestHeaders(
                extraHeaders,
                options.headers,
              ),
              params: this._serialize(requestParams),
            },
          ),
        ),
        map(event => {
          if (event instanceof HttpResponse) {
            return event.clone({
              body: this.formatResponseBody<T, E>(event),
            });
          }

          return event;
        }),
        catchError((error: HttpErrorResponse) =>
          this.handleError(error, { ...options, reportProgress: true }),
        ),
      );
    }

    return this.getExtraHeaders().pipe(
      take(1),
      map(extraHeaders => ({
        endpoint,
        extraHeaders,
      })),
      switchMap(({ endpoint: requestEndpoint, extraHeaders }) =>
        this.http.delete<RawSuccessResponseData<T>>(
          `${baseUrl}/${requestEndpoint}`,
          {
            reportProgress: options.reportProgress,
            observe: 'response',
            withCredentials: options.withCredentials,
            headers: this.mergeExtraHeadersWithRequestHeaders(
              extraHeaders,
              options.headers,
            ),
            params: this._serialize(requestParams),
          },
        ),
      ),
      map(event => this.formatResponseBody<T, E>(event)),
      catchError((error: HttpErrorResponse) =>
        this.handleError(error, { ...options, reportProgress: false }),
      ),
    );
  }

  private getApplicationInfoHeaders(): Observable<HttpHeaders> {
    let headers = new HttpHeaders();

    // set the locale header
    if (this.localization.locale) {
      headers = headers.set(
        FREELANCER_APP_LOCALE_HEADER,
        this.localization.locale,
      );
    }
    // set the app name header
    if (this.appName) {
      headers = headers.set(FREELANCER_APP_NAME_HEADER, this.appName);
    }

    if (isPlatformServer(this.platformId)) {
      return of(headers);
    }

    return combineLatest([
      this.pwa.appVersion(),
      this.pwa.nativeDeviceInfo(),
    ]).pipe(
      map(([appVersion, nativeDeviceInfo]) => {
        // set the build timestamp header
        if (window?.webapp?.version?.buildTimestamp) {
          headers = headers.set(
            FREELANCER_APP_BUILD_TIMESTAMP_HEADER,
            `${Math.round(window.webapp.version.buildTimestamp / 1000)}`,
          );
        }

        if (appVersion) {
          headers = headers.set(
            FREELANCER_APP_VERSION_HEADER,
            Object.entries(appVersion)
              .filter(
                ([key, value]) =>
                  isDefined(value) &&
                  // Specify the properties to be included in the header.
                  [
                    'gitRevision',
                    'buildTimestampInSeconds',
                    'nativeVersion',
                    'nativeBuildTimestampInSeconds',
                  ].includes(key),
              )
              .map(([key, value]) => `${key}=${value}`)
              .join(', '),
          );
        }
        if (nativeDeviceInfo) {
          headers = headers.set(
            FREELANCER_APP_NATIVE_DEVICE_INFO,
            /**
             * Percent-encode all string property because the value coming
             * from the Capacitor Device API could contain non-ASCII characters,
             * which cannot be used in HTTP headers.
             */
            Object.entries({
              // We did not encode UUID and OS version because it is expected
              // to contains only alphanumeric characters and dash.
              uuid: nativeDeviceInfo.uuid,
              osVersion: nativeDeviceInfo.osVersion,
              model: encodeURIComponent(nativeDeviceInfo.model),
              manufacturer: encodeURIComponent(nativeDeviceInfo.manufacturer),
              isVirtual: nativeDeviceInfo.isVirtual.toString(),
            })
              .map(([key, value]) => `${key}=${value}`)
              .join(', '),
          );
        }
        headers = headers.set(
          FREELANCER_APP_PLATFORM_HEADER,
          this.pwa.getPlatform(),
        );
        headers = headers.set(
          FREELANCER_APP_IS_NATIVE_HEADER,
          this.pwa.isNative().toString(),
        );
        headers = headers.set(
          FREELANCER_APP_IS_INSTALLED_HEADER,
          this.pwa.isInstalled().toString(),
        );

        return headers;
      }),
    );
  }

  /**
   * Create an observable that contains an array of extra HTTP headers
   * that we want to include in all HTTP request.
   * @returns An observable of HTTP Header.
   */
  private getExtraHeaders(): Observable<HttpHeaders[]> {
    return combineLatest([
      ...this.authProviders.map(p => p.getAuthorizationHeader()),
      this.getApplicationInfoHeaders(),
      this.abTestOverridesHeader?.getOverridesHeader?.() ??
        of(new HttpHeaders()),
      this.tracking?.getTrackingHeaders?.() ?? of(new HttpHeaders()),
    ]);
  }
}
