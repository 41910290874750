import { isPlatformServer } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgZone,
  PLATFORM_ID,
  Testability,
} from '@angular/core';
import type { RouterEvent } from '@angular/router';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { EMPTY, merge, Observable, Subscription } from 'rxjs';
import {
  buffer,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { Tracking } from './tracking.service';

/**
 * The Long Tasks API is still an experimental feature. These type definitions
 * can be replaced once they are included in `lib.dom`.
 * ref:  * https://w3c.github.io/longtasks/#performancelongtasktiming
 */
interface TaskAttributionTiming {
  readonly containerId: string;
  readonly containerName: string;
  readonly containerSrc: string;
  readonly containerType: string;
}
type PerformanceLongTaskTiming = {
  readonly attribution: TaskAttributionTiming[];
  readonly entryType: 'longtask';
} & PerformanceEntry;

type NavigationEvent =
  | {
      type: 'start';
      url?: string;
    }
  | {
      type: 'finish';
      url: string;
    };

@Component({
  selector: 'fl-long-task-tracking',
  template: `<ng-container></ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LongTaskTrackingComponent implements OnInit, OnDestroy {
  private routerSubscription = new Subscription();
  private isInitialNavigation = true;
  // Ref: https://web.dev/tbt/#what-is-tbt
  // The blocking time of a given long task is its duration in excess of 50 ms
  private LONG_TASK_BLOCKING_THRESHOLD = 50;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private tracking: Tracking,
    private testability: Testability,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit(): void {
    // As the long task API is still an experimental feature.
    // Skip if the long task is not supported in the current browser.
    if (
      isPlatformServer(this.platformId) ||
      !window.PerformanceObserver?.supportedEntryTypes?.includes('longtask')
    ) {
      return;
    }

    this.ngZone.runOutsideAngular(async () => {
      const longTasks$ = new Observable<PerformanceLongTaskTiming>(observer => {
        try {
          const performanceObserver = new PerformanceObserver(list => {
            list
              .getEntries()
              // At the moment, we only register the observer for long task,
              // so every entry should be a long tas entry.
              .filter(this.isLongTaskEntry)
              .forEach(entry => {
                /**
                 * The "name" in the performance entry is usually "unknown" or "self". They are
                 * correspond to task outside of the event loop and task from event loop of the
                 * browsing context respectively
                 * For more details, see https://w3c.github.io/longtasks/#performancelongtasktiming
                 */
                observer.next(entry);
              });
          });

          // Register the performance observer for long task notifications.
          performanceObserver.observe({
            type: 'longtask',
            // Only include historical entries captured for initial
            // navigation. For subsequent navigation, we would like to capture
            // entries after the NavigationStart event. This is because long
            // blocking task could happened before we started observing at the
            // initial navigation.
            buffered: this.isInitialNavigation,
          });

          return () => {
            performanceObserver.disconnect();
          };
        } catch (e: any) {
          // Catch errors since some browsers throw when using the new `type` option.
          // https://bugs.webkit.org/show_bug.cgi?id=209216
          observer.complete();
        }
      });

      const navigationStart$: Observable<NavigationEvent> =
        this.router.events.pipe(
          filter((e): e is RouterEvent => e instanceof NavigationStart),
          map(e => e.url),
          // Only captures navigation to another URL path.
          startWith(undefined),
          pairwise(),
          filter(
            ([prevUrl, currUrl]) =>
              prevUrl?.replace(/\?.*$/, '') !== currUrl?.replace(/\?.*$/, ''),
          ),
          map(([_, currUrl]) => currUrl),
          // Start with undefined for the initial navigation.
          startWith(undefined),
          map(url => ({
            type: 'start',
            url,
          })),
        );
      const navigationEnd$: Observable<RouterEvent> = this.router.events.pipe(
        filter(
          (e): e is RouterEvent =>
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel ||
            e instanceof NavigationError,
        ),
      );
      // Delay the navigation end event until the app is stable.
      const stableAfterNavigationEnd$: Observable<NavigationEvent> =
        navigationEnd$.pipe(
          switchMap(
            event =>
              new Observable<NavigationEvent>(observer => {
                let waitingForCallback = true;
                this.ngZone.runOutsideAngular(() => {
                  this.testability.whenStable(() => {
                    if (waitingForCallback) {
                      observer.next({
                        type: 'finish',
                        url: event.url,
                      });
                      observer.complete();
                    }
                  }, 60_000);
                });
                return () => {
                  waitingForCallback = false;
                };
              }),
          ),
        );

      this.routerSubscription.add(
        merge(navigationStart$, stableAfterNavigationEnd$)
          .pipe(
            // Ignore consecutive navigation start or finish event
            // as that might reset the PerformanceObserver.
            distinctUntilChanged((prev, curr) => prev.type === curr.type),
            // Only subscribe to the long task performance observer during
            // navigation (from start to app stable).
            switchMap(navigationEvent => {
              if (navigationEvent.type === 'start') {
                return longTasks$;
              }
              return EMPTY;
            }),
            buffer(stableAfterNavigationEnd$),
            filter(l => l.length > 0),
            withLatestFrom(navigationEnd$.pipe(map(e => e.url))),
          )
          .subscribe(([longTaskEntries, url]) => {
            // Compute the longest task and total time spent on long task
            // for this navigation.
            let longestTaskBlockingTime = 0;
            let totalLongTaskBlockingTime = 0;
            longTaskEntries.forEach(entry => {
              const blockingTime =
                entry.duration - this.LONG_TASK_BLOCKING_THRESHOLD;
              if (blockingTime > longestTaskBlockingTime) {
                longestTaskBlockingTime = blockingTime;
              }
              totalLongTaskBlockingTime += blockingTime;
            });

            this.tracking.track('long_task_on_navigation', {
              url,
              isInitialNavigation: this.isInitialNavigation,
              longestTaskBlockingTime,
              totalLongTaskBlockingTime,
            });
            this.isInitialNavigation = false;
          }),
      );
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  private isLongTaskEntry(
    entry: PerformanceEntry,
  ): entry is PerformanceLongTaskTiming {
    return entry.entryType === 'longtask' && 'attribution' in entry;
  }
}
