export enum IconColor {
  FOREGROUND = 'foreground',
  DARK = 'dark',
  LIGHT = 'light',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  WHITE = 'white',
  CONTEST = 'contest',
  RECRUITER_LIGHT = 'recruiter-light',
  AQUA_LIGHT = 'aqua-light',
  BLUE_XDARK = 'blue-xdark',
  ORANGE_XDARK = 'orange-xdark',
  INHERIT = 'inherit',
}

export enum HoverColor {
  CURRENT = 'currentColor',
  DARK = 'dark',
  LIGHT = 'light',
  PRIMARY = 'primary',
}

export enum IconSize {
  XXLARGE = 'xxlarge',
  XLARGE = 'xlarge',
  LARGE = 'large',
  MID = 'mid',
  SMALL = 'small',
  XSMALL = 'xsmall',
}

export enum IconBackdrop {
  PRIMARY = 'primary',
  PINK = 'pink',
  ERROR = 'error',
  DARK = 'dark',
  PINK_XLIGHT = 'pink-xlight',
  AQUA_LIGHT = 'aqua-light',
  SUCCESS = 'success',
  ORANGE_XXXLIGHT = 'orange-xxxlight',
  WHITE = 'white',
  TRANSPARENT = 'transparent',
}

export enum IconBackdropStyle {
  SOLID = 'solid',
  PULSE = 'pulse',
  TRANSPARENT = 'transparent',
}

export enum IconBackdropSize {
  LARGE = 'large',
  MID = 'mid',
  SMALL = 'small',
  XSMALL = 'xsmall',
  XXSMALL = 'xxsmall',
  XXXSMALL = 'xxxsmall',
}

export type IconHoverAnimation =
  | 'none'
  | 'spin'
  | 'highlight'
  | 'spin-and-fade'
  | 'spin-and-highlight';

export type IconClickAnimation =
  | 'none'
  | 'shrink'
  | 'highlight'
  | 'highlight-and-shrink';
