import { PoolApi } from 'api-typings/common/common';

/**
 * This enum only contains enterprises that used in Webapp
 * This is a temporary solution will get it from backend in T153666
 */
export enum Enterprise {
  DELOITTE_DC = 1,
  FACEBOOK = 3,
  PMI = 4,
  FLNLTD = 5,
  YARA = 7,
  FREIGHTLANCER = 19,
  HP = 27,
  GELATO = 182,
  TECH_MAHINDRA = 29,
}

/**
 * Pool IDs
 * This is a temporary solution will get it from backend in T261609
 */
export enum Pool {
  FREELANCER = 1,
  DELOITTE_DC = 2,
  FACEBOOK = 4,
  API_E2E = 5,
  FLNLTD = 6,
  NOKIA = 7,
  JOHNSON_AND_JOHNSON = 8,
  IBM = 9,
  FREIGHTLANCER = 10,
  HP = 13,
  DELOITTE_FTN = 89,
}

export enum PoolName {
  BAHR = 'bahr',
}

// TODO: T260416
// Production pool id 16 -> will not work in other environments
// Change to the dev pool id 1633 when testing
export const BAHR_POOL_ID = 16;

/**
 * Converts the PoolApi string enum to the corresponding integer ID.
 * This is a temporary solution until we clean up backend pool conversions T221545
 */
export const POOL_API_TO_POOL_ID_MAP: {
  readonly [k in Exclude<PoolApi, PoolApi.ARROW_PRIVATE>]: Pool;
} = {
  [PoolApi.FREELANCER]: Pool.FREELANCER,
  [PoolApi.DELOITTE_DC]: Pool.DELOITTE_DC,
  [PoolApi.FACEBOOK]: Pool.FACEBOOK,
  [PoolApi.API_E2E]: Pool.API_E2E,
  [PoolApi.FLNLTD]: Pool.FLNLTD,
  [PoolApi.NOKIA]: Pool.NOKIA,
  [PoolApi.JOHNSON_AND_JOHNSON]: Pool.JOHNSON_AND_JOHNSON,
  [PoolApi.IBM]: Pool.IBM,
  [PoolApi.FREIGHTLANCER]: Pool.FREIGHTLANCER,
  [PoolApi.DELOITTE_FTN]: Pool.DELOITTE_FTN,
};
