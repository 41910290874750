import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Environment, ENVIRONMENT_NAME } from '@freelancer/config';
import { PwaInitialState } from '@freelancer/initial-state';
import { PendingTasks } from '@freelancer/pending-tasks';

// /!\ DO ADD ANYTHING IN THERE WITHOUT TALKING TO FRONTEND INFRA FIRST /!\
// Application-wide code should be avoided as much as possible; does your code
// needs:
// - to be loaded on ALL logged-in pages?
// - AND to be loaded on ALL logged-out pages?
// - AND to be loaded on the Admin?
// - ...
// Probably not.
@Component({
  selector: 'app-root',
  template: `
    <app-toast-alerts />
    <fl-pwa />
    <fl-seo />
    <router-outlet />
    <fl-tracking *ngIf="isBrowser" />
    <fl-error-tracking></fl-error-tracking>
    <fl-location />
    <fl-modal />
    <fl-network-alert />
    <fl-notifications />
    <fl-update-native-alert />
    <fl-toast-alert-global />
    <fl-theme />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(ENVIRONMENT_NAME) private environment: Environment,
    private pendingTasks: PendingTasks,
    @Inject(DOCUMENT) private document: Document,
    private pwaInitialState: PwaInitialState,
  ) {}

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);

    // upon initial load, the app-shell displays a transparent image that registers
    // as the LCP for client side rendered pages. It is removed when the app loads.
    if (!this.pwaInitialState.hasRenderedOnServer()) {
      (
        this.document.getElementById('__pwa_loader_overlay__') as HTMLElement
      ).remove();
    }
    // FIXME: T267853 - detect Protractor env better
    if (this.isBrowser && this.environment !== 'prod') {
      this.pendingTasks.monitor();
    }
  }
}
