import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import type { TransferState } from '@angular/platform-browser';
import { makeStateKey } from '@angular/platform-browser';
import type { MetaReducer } from '@ngrx/store';
import type { TypedAction } from './actions';
import { mapValues } from './helpers';
import type { StoreState } from './store.model';

export const NGRX_STATE = makeStateKey<StoreState>('NGRX_STATE');

export function stateTransferReducerFactory(
  transferState: TransferState,
  platformId: Object,
): MetaReducer<StoreState, TypedAction> {
  // if on the server, register the transferState.onSerialize listener & pass
  // in the last state on serialization
  let saveState: StoreState;
  if (isPlatformServer(platformId)) {
    transferState.onSerialize(NGRX_STATE, () => saveState);
  }

  return reducer => (state, action) => {
    let nextState = state;
    // if on the client & a state has been stored, load it
    const hasState = transferState.hasKey(NGRX_STATE);
    if (isPlatformBrowser(platformId) && hasState) {
      const transferedState = transferState.get<StoreState | null>(
        NGRX_STATE,
        null,
      );
      transferState.remove(NGRX_STATE);
      nextState = {
        ...state,
        ...addHydrationMetaData(transferedState),
      };
    }
    saveState = reducer(nextState, action);
    return saveState;
  };
}

function addHydrationMetaData(
  transferedState: StoreState | null,
): StoreState | null {
  if (transferedState === null) {
    return null;
  }

  return mapValues(transferedState, collectionStateSlice =>
    mapValues(collectionStateSlice, userCollectionStateSlice =>
      userCollectionStateSlice
        ? {
            documents: mapValues(
              userCollectionStateSlice.documents,
              setIsTransferredFromSsr,
            ),
            queries: mapValues(
              userCollectionStateSlice.queries,
              setIsTransferredFromSsr,
            ),
          }
        : undefined,
    ),
  );
}

function setIsTransferredFromSsr<
  T extends {
    readonly isTransferredFromSSR?: boolean;
  },
>(somethingWithMetadata: T): T {
  return {
    ...somethingWithMetadata,
    isTransferredFromSSR: true,
  };
}
