import type { SelectItem } from '@freelancer/ui/select';

/**
 * HP project custom field names stored in DB
 */
export enum HPProjectCustomField {
  ADDITIONAL_FAILURE_CODE = 'additional_failure_code',
  ARRIVAL_TIME = 'arrival_time',
  ASSET_PRODUCT_NAME = 'asset_product_name',
  ASSET_PRODUCT_LINE_CD = 'asset_product_line_cd',
  BOOKING_DETAILS_RESOURCE_NAME = 'booking_details_resource_name',
  CASE_EXCHANGE_ID = 'case_exchange_id',
  CASE_PRIORITY = 'case_priority',
  CASE_SEVERITY = 'case_severity',
  DELAY_CODE = 'delay_code',
  ENGINEER_WORK_ORDER_NOTE = 'engineer_work_order_note',
  ENGINEER_WORK_ORDER_NOTE_DESCRIPTION = 'engineer_work_order_note_description',
  ENGINEER_WORK_ORDER_NOTE_USERNAME = 'engineer_work_order_note_username',
  ENGINEER_WORK_ORDER_NOTE_NUMBER = 'engineer_work_order_note_number',
  FAILURE_CODE = 'failure_code',
  HP_ERROR_STATE = 'hp_error_state',
  INSTALLED_PART_DESCRIPTION = 'part_description',
  INSTALLED_PART_NUMBER = 'part_number',
  INSTALLED_PART_SERIAL_NUMBER = 'installed_serial_number',
  LABOR_DURATION = 'labor_duration',
  MAIN_COMPONENT = 'main_component',
  NMU_CLASS = 'nmu_class',
  NMU_SUBCLASS = 'nmu_subclass',
  PART_DESCRIPTION = 'part_description',
  PART_NUMBER = 'part_number',
  PART_ORDER = 'part_order',
  PART_ORDER_LINE_ITEM = 'part_order_line_item',
  PART_ORDER_NUMBER = 'part_order_number',
  PRIORITY_ALERT = 'priority_alert',
  PROGRESS_BLOCKED = 'progress_blocked',
  REMOVED_PART_DESCRIPTION = 'removed_part_description',
  REMOVED_PART_NUMBER = 'removed_part_number',
  REMOVED_PART_SERIAL_NUMBER = 'removed_serial_number',
  REMOVED_SERIAL_NUMBER = 'removed_serial_number',
  REPAIR_CLASS = 'repair_class',
  RESOLUTION_NOTES = 'resolution_notes',
  RETURN_OVERRIDE_FLAG = 'return_override_flag',
  RETURN_OVERRIDE_REASON = 'return_override_reason',
  REQUESTED_DATE_TIME = 'requested_date_time',
  SERVICE_DELIVERY_CONTACT_FIRST_NAME = 'service_delivery_contact_first_name',
  SERVICE_DELIVERY_CONTACT_LAST_NAME = 'service_delivery_contact_last_name',
  SERVICE_DELIVERY_CONTACT_PHONE = 'service_delivery_contact_phone',
  SERVICE_END_DATE_TIME = 'service_end_date_time',
  SERVICE_START_DATE_TIME = 'service_start_date_time',
  SUBJECT = 'subject',
  SYSTEM_FIXED_TIME = 'system_fixed_time',
  TASK_COMPLETED = 'task_completed',
  TIME_LOG = 'time_log',
  TIME_LOG_DURATION = 'time_log_duration',
  TIME_LOG_LABOR_TYPE = 'time_log_labor_type',
  TRAVEL_DURATION = 'travel_duration',
  TRAVEL_ZONE = 'travel_zone',
  USAGE_CODE = 'usage_code',
  VERSION_NUMBER = 'version_number',
  WORK_ORDER_ID = 'work_order_id',
  WORK_ORDER_NOTE_DESCRIPTION = 'work_order_note_description',
  WORK_ORDER_NOTE_HISTORY = 'work_order_note_history',
  WORK_ORDER_NOTE_NUMBER = 'work_order_note_number',
  WORK_ORDER_PRIMARY_INCIDENT_TYPE = 'work_order_primary_incident_type',
}

/**
 * HP Task section names that are consistent with names in GAF php and HPIS
 */
export enum HPTaskSectionName {
  ACCEPTED = 'Accepted',
  AWAITING_DELIVERY = 'Awaiting Delivery',
  PARTS_RECEIVED = 'Parts Received',
  PARTS_CONFIRMED = 'Parts Confirmed',
  CUSTOMER_RESCHEDULE = 'Customer Reschedule',
  CUSTOMER_DELAY = 'Customer Delayed',
  CONFIRM_DATE_TIME = 'Confirm booking with Customer',
  TRAVELING = 'Traveling',
  ONSITE = 'Onsite',
  ONSITE_POST_REPAIR_PHOTO = 'Onsite Post-Repair Photo',
  PROBLEM_RESOLUTION = 'Problem Resolution',
  CUSTOMER_SIGNOFF = 'Customer Signoff',
  PARTS_RETURN = 'Parts Return',
  COMPLETE = 'Complete',
}

/**
 * Steps of the HP main task flow
 */
export enum HPTaskSectionStep {
  PARTS_CONFIRMATION = 1,
  CONFIRM_BOOKING = 2,
  BOOKING_CONFIRMED = 3,
  CONFIRM_ARRIVAL = 4,
  PRE_REPAIR_PHOTO = 5,
  POST_REPAIR_PHOTO = 6,
  PROJECT_RESOLUTION = 7,
  CUSTOMER_SIGNOFF = 8,
  PARTS_RETURN = 9,
  COMPLETE = 10,
  AWAITING_DELIVERY = 11,
}

/**
 * List of HP queue regions dispatcher can filter by in the Field Services Tracker
 */
export enum HPBookingResource {
  AUA_EEG_PR_FLN = 'AUA-EEG-PR-FLN',
  INY_EEG_FREELANCERHYDPPS = 'INY_EEG_FreelancerHydPPS',
  INY_EEG_FREELANCERLUCKNOW = 'INY_EEG_FreelancerLucknow',
  INY_EEG_FREELANCERVISHAKAPATANAM = 'INY_EEG_FreelancerVishakapatanam',
  INY_EEG_FREELANCERMUM = 'INY_EEG_FreelancerMum',
  INY_EEG_FREELANCERDELH = 'INY_EEG_FreelancerDelh',
  INY_EEG_FREELANCERBENGALURU = 'INY_EEG_FreelancerBengaluru',
  INY_EEG_FREELANCERCHENNAI = 'INY_EEG_FreelancerChennai',
  INY_EEG_FREELANCERKOLKATA = 'INY_EEG_FreelancerKolkata',
  INY_EEG_FREELANCERAHMEDABAD = 'INY_EEG_FreelancerAhmedabad',
  INY_EEG_FREELANCERPUNE = 'INY_EEG_FreelancerPune',
  INY_EEG_FREELANCERPADAPPAI = 'INY_EEG_FreelancerPadappai',
  INY_EEG_FREELANCERJAMSHEDPUR = 'INY_EEG_FreelancerJamshedpur',
  INY_EEG_FREELANCERBHUBANESWAR = 'INY_EEG_FreelancerBhubaneswar',
  IDY_SO_FLN_EEG = 'IDY_SO FLN EEG',
  MYY_SO_FLN_EEG = 'MYY_SO FLN EEG',
  AMS_US_EEG_FREELANCER_PS_BF = 'AMS-US-EEG-Freelancer-PS-BF',
  INY_EEG_FREELANCERKARIMNAGAR = 'INY_EEG_FreelancerKarimnagar',
  INY_EEG_FREELANCERNIZAMABAD = 'INY_EEG_FreelancerNizamabad',
  INY_EEG_FREELANCERSURAT = 'INY_EEG_FreelancerSurat',
  INY_EEG_FREELANCERINDORE = 'INY_EEG_FreelancerIndore',
  INY_EEG_FREELANCERBHOPAL = 'INY_EEG_FreelancerBhopal',
  INY_EEG_FREELANCERBHARUCH = 'INY_EEG_FreelancerBharuch',
  INY_EEG_FREELANCERGURGAON = 'INY_EEG_FreelancerGurgaon',
  INY_EEG_FREELANCERNOIDA = 'INY_EEG_FreelancerNoida',
  INY_EEG_FREELANCERPUDUCHERRY = 'INY_EEG_FreelancerPuducherry',
  INY_EEG_FREELANCERVELLORE = 'INY_EEG_FreelancerVellore',
  INY_EEG_FREELANCERVADODARA = 'INY_EEG_FreelancerVadodara',
  INY_EEG_FREELANCERKANCHIPURAM = 'INY_EEG_FreelancerKanchipuram',
  INY_EEG_FREELANCERTIRUVALLUR = 'INY_EEG_FreelancerTiruvallur',
  INY_EEG_FREELANCERCOIMBATORE = 'INY_EEG_FreelancerCoimbatore',
  INY_EEG_FREELANCERSALEM = 'INY_EEG_FreelancerSalem',
  INY_EEG_FREELANCERMADURAI = 'INY_EEG_FreelancerMadurai',
  INY_EEG_FREELANCERTIRUCHIRAPPALLI = 'INY_EEG_FreelancerTiruchirappalli',
  INY_EEG_FREELANCERTIRUNELVELI = 'INY_EEG_FreelancerTirunelveli',
  INY_EEG_FREELANCERKANYAKUMARI = 'INY_EEG_FreelancerKanyakumari',
  INY_EEG_FREELANCERKRISHNAGIRI = 'INY_EEG_FreelancerKrishnagiri',
  INY_EEG_FREELANCERTUTICORIN = 'INY_EEG_FreelancerTuticorin',
  INY_EEG_FREELANCERCUDDALORE = 'INY_EEG_FreelancerCuddalore',
  INY_EEG_FREELANCERDINDIGUL = 'INY_EEG_FreelancerDindigul',
  INY_EEG_FREELANCERVILLUPURAM = 'INY_EEG_FreelancerVillupuram',
  INY_EEG_FREELANCERVIRUDHUNAGAR = 'INY_EEG_FreelancerVirudhunagar',
  INY_EEG_FREELANCERSIVAGANGA = 'INY_EEG_FreelancerSivaganga',
  INY_EEG_FREELANCERNAMAKKAL = 'INY_EEG_FreelancerNamakkal',
  INY_EEG_FREELANCERERODE = 'INY_EEG_FreelancerErode',
  INY_EEG_FREELANCERDHARMAPURI = 'INY_EEG_FreelancerDharmapuri',
  INY_EEG_FREELANCERKARUR = 'INY_EEG_FreelancerKarur',
  INY_EEG_FREELANCERTIRUVANNAMALAI = 'INY_EEG_FreelancerTiruvannamalai',
  INY_EEG_FREELANCERRAMANATHAPURAM = 'INY_EEG_FreelancerRamanathapuram',
  INY_EEG_FREELANCERTHANJAVUR = 'INY_EEG_FreelancerThanjavur',
  INY_EEG_FREELANCERPUDUKKOTTAI = 'INY_EEG_FreelancerPudukkottai',
  INY_EEG_FREELANCERTHENI = 'INY_EEG_FreelancerTheni',
  INY_EEG_FREELANCERNILGIRIS = 'INY_EEG_FreelancerNilgiris',
  INY_EEG_FREELANCERNAGAPATTINAM = 'INY_EEG_FreelancerNagapattinam',
  INY_EEG_FREELANCERPERAMBALUR = 'INY_EEG_FreelancerPerambalur',
  INY_EEG_FREELANCERARIYALUR = 'INY_EEG_FreelancerAriyalur',
}

type StatusStepMapping = {
  readonly [k in HPTaskSectionName]: HPTaskSectionStep;
};

/**
 * Mappings of HP task section names to their corresponding task flow step
 */
export const HP_TASK_SECTION_STEP_NUMBER_MAPPING: StatusStepMapping = {
  [HPTaskSectionName.ACCEPTED]: HPTaskSectionStep.PARTS_CONFIRMATION,
  [HPTaskSectionName.AWAITING_DELIVERY]: HPTaskSectionStep.AWAITING_DELIVERY,
  [HPTaskSectionName.PARTS_RECEIVED]: HPTaskSectionStep.PARTS_CONFIRMATION,
  [HPTaskSectionName.PARTS_CONFIRMED]: HPTaskSectionStep.CONFIRM_BOOKING,
  [HPTaskSectionName.CUSTOMER_RESCHEDULE]: HPTaskSectionStep.CONFIRM_BOOKING,
  [HPTaskSectionName.CUSTOMER_DELAY]: HPTaskSectionStep.CONFIRM_BOOKING,
  [HPTaskSectionName.CONFIRM_DATE_TIME]: HPTaskSectionStep.BOOKING_CONFIRMED,
  [HPTaskSectionName.TRAVELING]: HPTaskSectionStep.CONFIRM_ARRIVAL,
  [HPTaskSectionName.ONSITE]: HPTaskSectionStep.PRE_REPAIR_PHOTO,
  [HPTaskSectionName.ONSITE_POST_REPAIR_PHOTO]:
    HPTaskSectionStep.POST_REPAIR_PHOTO,
  [HPTaskSectionName.PROBLEM_RESOLUTION]: HPTaskSectionStep.PROJECT_RESOLUTION,
  [HPTaskSectionName.CUSTOMER_SIGNOFF]: HPTaskSectionStep.CUSTOMER_SIGNOFF,
  [HPTaskSectionName.PARTS_RETURN]: HPTaskSectionStep.PARTS_RETURN,
  [HPTaskSectionName.COMPLETE]: HPTaskSectionStep.COMPLETE,
};

/**
 * Mappings of task flow steps to the HP task section name that belongs to the certain step
 */
export const HP_STEP_NUMBER_FIRST_TASK_SECTION_MAPPING: {
  readonly [k in HPTaskSectionStep]: HPTaskSectionName;
} = {
  [HPTaskSectionStep.AWAITING_DELIVERY]: HPTaskSectionName.AWAITING_DELIVERY,
  [HPTaskSectionStep.PARTS_CONFIRMATION]: HPTaskSectionName.PARTS_RECEIVED,
  [HPTaskSectionStep.CONFIRM_BOOKING]: HPTaskSectionName.PARTS_CONFIRMED,
  [HPTaskSectionStep.BOOKING_CONFIRMED]: HPTaskSectionName.CONFIRM_DATE_TIME,
  [HPTaskSectionStep.CONFIRM_ARRIVAL]: HPTaskSectionName.TRAVELING,
  [HPTaskSectionStep.PRE_REPAIR_PHOTO]: HPTaskSectionName.ONSITE,
  [HPTaskSectionStep.POST_REPAIR_PHOTO]:
    HPTaskSectionName.ONSITE_POST_REPAIR_PHOTO,
  [HPTaskSectionStep.PROJECT_RESOLUTION]: HPTaskSectionName.PROBLEM_RESOLUTION,
  [HPTaskSectionStep.CUSTOMER_SIGNOFF]: HPTaskSectionName.CUSTOMER_SIGNOFF,
  [HPTaskSectionStep.PARTS_RETURN]: HPTaskSectionName.PARTS_RETURN,
  [HPTaskSectionStep.COMPLETE]: HPTaskSectionName.COMPLETE,
};

/**
 * Total number of HP task flow steps
 */
export const HP_TASK_SECTION_STEP_TOTAL = 10;

/**
 * Progress percentage of each HP task flow step that are used to display the progress bar
 */
export const HP_TASK_SECTION_STEP_PERCENT_PROGRESS_MAPPING: StatusStepMapping =
  Object.fromEntries(
    Object.entries(HP_TASK_SECTION_STEP_NUMBER_MAPPING).map(
      ([status, step]) => [
        status as HPTaskSectionName,
        (step * 100) / HP_TASK_SECTION_STEP_TOTAL,
      ],
    ),
  ) as StatusStepMapping;

/**
 * Displayed copies for titles of HP task flow steps
 */
export const HP_TASK_SECTION_STEP_DISPLAY_NAME: {
  readonly [k in HPTaskSectionStep]?: string;
} = {
  [HPTaskSectionStep.PARTS_CONFIRMATION]: 'Confirm Parts',
  [HPTaskSectionStep.CONFIRM_BOOKING]: 'Confirm Booking',
  [HPTaskSectionStep.BOOKING_CONFIRMED]: 'Travel to Job',
  [HPTaskSectionStep.CONFIRM_ARRIVAL]: 'Confirm Arrival',
  [HPTaskSectionStep.PRE_REPAIR_PHOTO]: 'Take Pre-repair Photos',
  [HPTaskSectionStep.POST_REPAIR_PHOTO]: 'Take Post-repair Photos',
  [HPTaskSectionStep.PROJECT_RESOLUTION]: 'Resolve Project',
  [HPTaskSectionStep.CUSTOMER_SIGNOFF]: 'Customer Signoff',
  [HPTaskSectionStep.PARTS_RETURN]: 'Return Parts',
  [HPTaskSectionStep.COMPLETE]: 'Work Complete',
  [HPTaskSectionStep.AWAITING_DELIVERY]: 'Waiting on Parts',
};

/**
 * Displayed copies for CTA of HP task flow steps
 */
export const HP_TASK_SECTION_SUBMIT_CTA: {
  readonly [k in HPTaskSectionStep]?: string;
} = {
  [HPTaskSectionStep.PARTS_CONFIRMATION]: $localize`Next`,
  [HPTaskSectionStep.CONFIRM_BOOKING]: $localize`Confirm booking`,
  [HPTaskSectionStep.BOOKING_CONFIRMED]: $localize`On my way`,
  [HPTaskSectionStep.CONFIRM_ARRIVAL]: $localize`Confirm arrival`,
  [HPTaskSectionStep.PRE_REPAIR_PHOTO]: $localize`Next`,
  [HPTaskSectionStep.POST_REPAIR_PHOTO]: $localize`Next`,
  [HPTaskSectionStep.PROJECT_RESOLUTION]: $localize`Next`,
  [HPTaskSectionStep.CUSTOMER_SIGNOFF]: $localize`Next`,
  [HPTaskSectionStep.PARTS_RETURN]: $localize`Done`,
  [HPTaskSectionStep.COMPLETE]: '',
};

/**
 * Custom field values to display for the confirm booking step
 */
export interface HPConfirmBookingCustomFields {
  readonly customerFirstName?: string;
  readonly customerLastName?: string;
  readonly customerPhoneNumber?: string;
  readonly productName?: string;
  readonly subject?: string;
}

/**
 * Form group fields for the confirm booking step
 */
export enum HPTaskSectionConfirmBookingField {
  DATE = 'date',
  START_TIME = 'start_time',
  END_TIME = 'end_time',
  DELAY_REASON = 'delay_reason',
  DELAY_REASON_DETAILS = 'delay_reason_details',
}

/**
 * Delay reason field values for task being delayed
 */
export enum HPBookingDelayReason {
  CUSTOMER_REQUESTED_DELAY = 'Customer Requested Delay',
  CUSTOMER_RESCHEDULE = 'Customer Reschedule',
}

/**
 * Delay reason field options for task being delayed
 */
export const HP_BOOKING_DELAY_REASON_OPTIONS: readonly SelectItem<
  '' | HPBookingDelayReason
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: 'Customer Reschedule',
    value: HPBookingDelayReason.CUSTOMER_REQUESTED_DELAY,
  },
  {
    displayText: 'Engineer Reschedule',
    value: HPBookingDelayReason.CUSTOMER_RESCHEDULE,
  },
];

/**
 * Constants for generating confirm booking time options
 */
export const HP_TASK_BOOKING_TIME_MINUTES_INTERVAL = 15;
export const HP_TASK_BOOKING_DEFAULT_START_END_INTERVAL = 4 * 60; // in minutes

/**
 * Display names of HP project resolution fields
 * Some of the fields doesn't have a display name since it is a custom field object or not displayed at all
 */
export const HP_PROJECT_RESOLUTION_FIELD_LABEL_DISPLAY_NAME_MAPPING: {
  readonly [k in HPProjectCustomField]?: string;
} = {
  [HPProjectCustomField.REPAIR_CLASS]: 'Repair class',
  [HPProjectCustomField.DELAY_CODE]: 'Delay code',
  [HPProjectCustomField.PART_ORDER_NUMBER]: 'Part order number',
  [HPProjectCustomField.INSTALLED_PART_NUMBER]: 'Installed part number',
  [HPProjectCustomField.INSTALLED_PART_SERIAL_NUMBER]:
    'Installed part serial number',
  [HPProjectCustomField.INSTALLED_PART_DESCRIPTION]:
    'Installed part description',
  [HPProjectCustomField.USAGE_CODE]: 'Usage code',
  [HPProjectCustomField.REMOVED_PART_NUMBER]: 'Removed part number',
  [HPProjectCustomField.REMOVED_PART_SERIAL_NUMBER]:
    'Removed part serial number',
  [HPProjectCustomField.REMOVED_PART_DESCRIPTION]: 'Removed part description',
  [HPProjectCustomField.FAILURE_CODE]: 'Failure code',
  [HPProjectCustomField.ADDITIONAL_FAILURE_CODE]: 'Additional failure code',
  [HPProjectCustomField.RESOLUTION_NOTES]: 'Resolution notes',
  [HPProjectCustomField.NMU_CLASS]: 'NMU Class',
  [HPProjectCustomField.NMU_SUBCLASS]: 'NMU Subclass',
  [HPProjectCustomField.VERSION_NUMBER]: 'Version Number',
  [HPProjectCustomField.RETURN_OVERRIDE_FLAG]: 'Returning part?',
  [HPProjectCustomField.RETURN_OVERRIDE_REASON]: 'Return override reason',
  [HPProjectCustomField.TRAVEL_ZONE]: 'Travel zone',
  [HPProjectCustomField.SERVICE_START_DATE_TIME]: 'Service start date time',
  [HPProjectCustomField.SERVICE_END_DATE_TIME]: 'Service end date time',
  [HPProjectCustomField.SYSTEM_FIXED_TIME]: 'System fixed date time',
};

/**
 * HP time log types
 */
export enum HPTimeLog {
  LABOR_DURATION = 'labor_duration',
  TRAVEL_DURATION = 'travel_duration',
}

/**
 * The string values stored in DB that determines HP time log types
 */
export const HP_TIME_LOG_LABOR_TYPE_VALUE_MAPPING: {
  readonly [k in HPTimeLog]: string;
} = {
  [HPTimeLog.LABOR_DURATION]: 'Hardware Repair/Installation',
  [HPTimeLog.TRAVEL_DURATION]: 'Travel',
};

/**
 * Label for HP time log fields
 */
export const HP_TIME_LOG_LABOR_TYPE_FIELD_LABEL_MAPPING: {
  readonly [k in HPTimeLog]: string;
} = {
  [HPTimeLog.LABOR_DURATION]: 'Labor Time',
  [HPTimeLog.TRAVEL_DURATION]: 'Travel Time',
};

/**
 * Label placeholder strings for HP project resolution fields
 * Some of the fields doesn't have a placeholder since it is a custom field object or display-only
 */
export const HP_PROJECT_RESOLUTION_FIELD_LABEL_PLACEHOLDER_MAPPING: {
  readonly [k in HPProjectCustomField]?: string;
} = {
  [HPProjectCustomField.REPAIR_CLASS]: 'Select repair class',
  [HPProjectCustomField.DELAY_CODE]: 'Select delay code',
  [HPProjectCustomField.USAGE_CODE]: 'Select usage code',
  [HPProjectCustomField.REMOVED_PART_NUMBER]: 'Enter part number',
  [HPProjectCustomField.REMOVED_PART_SERIAL_NUMBER]: 'Enter part serial number',
  [HPProjectCustomField.REMOVED_PART_DESCRIPTION]: 'Enter description',
  [HPProjectCustomField.FAILURE_CODE]: 'Select failure code',
  [HPProjectCustomField.ADDITIONAL_FAILURE_CODE]:
    'Select additional failure code',
  [HPProjectCustomField.RESOLUTION_NOTES]: 'Enter resolution notes',
  [HPProjectCustomField.TIME_LOG]: 'Select minutes',
  [HPProjectCustomField.NMU_CLASS]: 'Select NMU class',
  [HPProjectCustomField.NMU_SUBCLASS]: 'Select NMU subclass',
  [HPProjectCustomField.VERSION_NUMBER]: 'Enter version number used for repair',
  [HPProjectCustomField.RETURN_OVERRIDE_FLAG]:
    'Select the return status of the part',
  [HPProjectCustomField.RETURN_OVERRIDE_REASON]: 'Select a reason',
  [HPProjectCustomField.TRAVEL_ZONE]: 'Select travel zone',
};

/**
 * Options for the HP repair class field
 */
export enum HPRepairClass {
  AO = 'AO - Align or Adjust Only',
  AS = 'AS - ADP Onsite Repair',
  AU = 'AU - Site Audits',
  BS = 'BS – Type-B Software Support',
  CD = 'CD - Cust Declined SN',
  CO = 'CO - Rpr,Compt,Parts Repl',
  DG = 'DG - Remote Support Maintenance',
  DO = 'DO - Documentation',
  IN = 'IN - Installation',
  IR = 'IR - Incomplete Repair',
  ND = 'ND - Not Defined',
  NT = 'NT - No Trouble Found',
  PM = 'PM - Preventative Maint',
  SN = 'SN - Service Note',
  SW = 'SW - Software Support',
  UC = 'UC - User Caused',
  WK = 'WK - Workmanship',
  ZZ = 'ZZ – MPS Device Replaced',
}

export const NON_PART_PRODUCT_LINE_CDS: readonly string[] = [
  'KV',
  'M7',
  'M8',
  'PKV00',
  'PM700',
  'PM800',
];

export const HP_REPAIR_CLASS_OPTION_AO: SelectItem<HPRepairClass> = {
  displayText: HPRepairClass.AO,
  value: HPRepairClass.AO,
};

export const HP_REPAIR_CLASS_OPTION_BS: SelectItem<HPRepairClass> = {
  displayText: HPRepairClass.BS,
  value: HPRepairClass.BS,
};

export const HP_REPAIR_CLASS_OPTIONS_BASE: readonly SelectItem<
  '' | HPRepairClass
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: HPRepairClass.AS,
    value: HPRepairClass.AS,
  },
  {
    displayText: HPRepairClass.AU,
    value: HPRepairClass.AU,
  },
  {
    displayText: HPRepairClass.CD,
    value: HPRepairClass.CD,
  },
  {
    displayText: HPRepairClass.CO,
    value: HPRepairClass.CO,
  },
  {
    displayText: HPRepairClass.DG,
    value: HPRepairClass.DG,
  },
  {
    displayText: HPRepairClass.DO,
    value: HPRepairClass.DO,
  },
  {
    displayText: HPRepairClass.IN,
    value: HPRepairClass.IN,
  },
  {
    displayText: HPRepairClass.IR,
    value: HPRepairClass.IR,
  },
  {
    displayText: HPRepairClass.ND,
    value: HPRepairClass.ND,
  },
  {
    displayText: HPRepairClass.NT,
    value: HPRepairClass.NT,
  },
  {
    displayText: HPRepairClass.PM,
    value: HPRepairClass.PM,
  },
  {
    displayText: HPRepairClass.SN,
    value: HPRepairClass.SN,
  },
  {
    displayText: HPRepairClass.SW,
    value: HPRepairClass.SW,
  },
  {
    displayText: HPRepairClass.UC,
    value: HPRepairClass.UC,
  },
  {
    displayText: HPRepairClass.WK,
    value: HPRepairClass.WK,
  },
  {
    displayText: HPRepairClass.ZZ,
    value: HPRepairClass.ZZ,
  },
];

export enum HPNMUClassBase {
  CMOS_RESET = 'CMOS Reset',
  BIOS_RECOVERY = 'BIOS Recovery (Win + B)',
  RESEAT_CABLE_OR_CONNECTOR = 'Reseating of Cables / Connectors',
  MEMORY_RESEAT = 'Memory Reseat',
  CLEANING_OF_MEMORY_SLOT = 'Cleaning of Memory Slot',
  OS_RESTORE = 'OS Restore',
  BOOT_FROM_CUSTOM_USB_DRIVE = 'Boot from Custom USB Drive',
  WINDOWS_SETTINGS_REINSTALL = 'Windows Settings/Reinstall',
  POWER_DRAIN = 'Power Drain',
  BIOS_UPDATE = 'BIOS Update',
  DRIVER_UPDATE_TYPE_VERSION = 'Driver Update Type Version',
  FIRMWARE_UPDATE = 'Firmware Update',
}

export enum HPNMUSubclassBase {
  WIN_V = 'Win+V',
  THERMAL_PASTE_HEATSINK_CLEAN_AND_REFIX = 'Thermal Paste Heatsink Clean and Refix',
  DC_IN_CABLE = 'DC-IN CABLE',
  HDD_CABLE = 'HDD CABLE',
  LCD_DISPLAY_CABLE = 'LCD DISPLAY CABLE',
  WEBCAM_CABLE = 'Webcam Cable',
  WLAN_CABLE = 'WLAN Cable',
  WWAN_CABLE = 'WWAN Cable',
  CMOS_BATTERY_CABLE = 'CMOS Battery Cable',
  TOUCHPAD_CABLE = 'Touchpad Cable',
  POWER_BOARD_CABLE = 'Power Board Cable',
  ANTENNA_CABLE = 'Antenna Cable',
  KEYBOARD_CABLE = 'Keyboard Cable',
  USB_BOARD_CABLE = 'USB Board Cable',
  AUDIO_BOARD_CABLE = 'Audio Board Cable',
  BATTERY_CABLE = 'Battery Cable',
  SMART_CARD_READER_BOARD_CABLE = 'Smart Card Reader Board Cable',
  FAN_CABLE = 'Fan Cable',
}

export const HP_NMU_CLASS_BASE_SHOW_VERSION_INPUT: readonly HPNMUClassBase[] = [
  HPNMUClassBase.BIOS_UPDATE,
  HPNMUClassBase.DRIVER_UPDATE_TYPE_VERSION,
  HPNMUClassBase.FIRMWARE_UPDATE,
];

export const HP_NMU_SUBCLASS_OPTIONS: {
  readonly [k in HPNMUClassBase]?: readonly SelectItem<
    '' | HPNMUSubclassBase
  >[];
} = {
  [HPNMUClassBase.CMOS_RESET]: [
    { displayText: '', value: '', disabled: true },
    {
      displayText: HPNMUSubclassBase.WIN_V,
      value: HPNMUSubclassBase.WIN_V,
    },
  ],
  [HPNMUClassBase.BIOS_RECOVERY]: [
    { displayText: '', value: '', disabled: true },
    {
      displayText: HPNMUSubclassBase.THERMAL_PASTE_HEATSINK_CLEAN_AND_REFIX,
      value: HPNMUSubclassBase.THERMAL_PASTE_HEATSINK_CLEAN_AND_REFIX,
    },
  ],
  [HPNMUClassBase.RESEAT_CABLE_OR_CONNECTOR]: [
    { displayText: '', value: '', disabled: true },
    {
      displayText: HPNMUSubclassBase.DC_IN_CABLE,
      value: HPNMUSubclassBase.DC_IN_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.HDD_CABLE,
      value: HPNMUSubclassBase.HDD_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.LCD_DISPLAY_CABLE,
      value: HPNMUSubclassBase.LCD_DISPLAY_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.WEBCAM_CABLE,
      value: HPNMUSubclassBase.WEBCAM_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.WLAN_CABLE,
      value: HPNMUSubclassBase.WLAN_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.WWAN_CABLE,
      value: HPNMUSubclassBase.WWAN_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.CMOS_BATTERY_CABLE,
      value: HPNMUSubclassBase.CMOS_BATTERY_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.TOUCHPAD_CABLE,
      value: HPNMUSubclassBase.TOUCHPAD_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.POWER_BOARD_CABLE,
      value: HPNMUSubclassBase.POWER_BOARD_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.ANTENNA_CABLE,
      value: HPNMUSubclassBase.ANTENNA_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.KEYBOARD_CABLE,
      value: HPNMUSubclassBase.KEYBOARD_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.USB_BOARD_CABLE,
      value: HPNMUSubclassBase.USB_BOARD_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.AUDIO_BOARD_CABLE,
      value: HPNMUSubclassBase.AUDIO_BOARD_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.BATTERY_CABLE,
      value: HPNMUSubclassBase.BATTERY_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.SMART_CARD_READER_BOARD_CABLE,
      value: HPNMUSubclassBase.SMART_CARD_READER_BOARD_CABLE,
    },
    {
      displayText: HPNMUSubclassBase.FAN_CABLE,
      value: HPNMUSubclassBase.FAN_CABLE,
    },
  ],
};

export const HP_NMU_CLASS_BASE_OPTIONS: readonly SelectItem<
  '' | HPNMUClassBase
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: HPNMUClassBase.CMOS_RESET,
    value: HPNMUClassBase.CMOS_RESET,
  },
  {
    displayText: HPNMUClassBase.BIOS_RECOVERY,
    value: HPNMUClassBase.BIOS_RECOVERY,
  },
  {
    displayText: HPNMUClassBase.RESEAT_CABLE_OR_CONNECTOR,
    value: HPNMUClassBase.RESEAT_CABLE_OR_CONNECTOR,
  },
  {
    displayText: HPNMUClassBase.MEMORY_RESEAT,
    value: HPNMUClassBase.MEMORY_RESEAT,
  },
  {
    displayText: HPNMUClassBase.CLEANING_OF_MEMORY_SLOT,
    value: HPNMUClassBase.CLEANING_OF_MEMORY_SLOT,
  },
  {
    displayText: HPNMUClassBase.OS_RESTORE,
    value: HPNMUClassBase.OS_RESTORE,
  },
  {
    displayText: HPNMUClassBase.BOOT_FROM_CUSTOM_USB_DRIVE,
    value: HPNMUClassBase.BOOT_FROM_CUSTOM_USB_DRIVE,
  },
  {
    displayText: HPNMUClassBase.WINDOWS_SETTINGS_REINSTALL,
    value: HPNMUClassBase.WINDOWS_SETTINGS_REINSTALL,
  },
  {
    displayText: HPNMUClassBase.POWER_DRAIN,
    value: HPNMUClassBase.POWER_DRAIN,
  },
  {
    displayText: HPNMUClassBase.BIOS_UPDATE,
    value: HPNMUClassBase.BIOS_UPDATE,
  },
  {
    displayText: HPNMUClassBase.DRIVER_UPDATE_TYPE_VERSION,
    value: HPNMUClassBase.DRIVER_UPDATE_TYPE_VERSION,
  },
  {
    displayText: HPNMUClassBase.FIRMWARE_UPDATE,
    value: HPNMUClassBase.FIRMWARE_UPDATE,
  },
];

/**
 * Options for the HP delay code field
 */
export enum HPDelayCode {
  RESPONSE_MET = '0-Response Met',
  RETURNED_TO_OFFICE = '1-Returned to office for resource',
  PARTS_ISSUE = '2-Parts Issue',
  NO_CE_RESOURCES = '3-No CE Resources Available',
  WRONG_CE = '4-Subcase Sent to wrong CE',
  TRAVEL_DELAY = '5-Travel Delay',
  NO_QUALIFIED_CE = '6-No Trained/Qualified CE Available',
  SUBCASE_LATE = '7-Subcase recd late from Call Center',
  CUSTOMER_CAUSED_DELAY = '8-Customer caused Delay',
  OTHER = '9-Other',
  UNABLE_TO_REACH_CUSTOMER = '10-Unable to reach customer',
  CANT_PROVIDE_TS_INFO = '11-Customer can’t provide TS information',
  CUSTOMER_INACCURATE_DIAGNOSE = '12-Inaccurate diagnose by customer action',
  PARTNER_INACCURATE_DIAGNOSE = '13-Inaccurate diagnose by partner action',
  ACCESS_DENIED = '14-Access denied by customer',
}

/**
 * Display name map for the HP delay codes
 */
export const HP_DELAY_CODE_DISPLAY_NAME_MAP: {
  readonly [k in HPDelayCode]: string;
} = {
  [HPDelayCode.RESPONSE_MET]: '0 - Response Met',
  [HPDelayCode.RETURNED_TO_OFFICE]: '1 - Returned to office for resource',
  [HPDelayCode.PARTS_ISSUE]: '2 - Parts Issue',
  [HPDelayCode.NO_CE_RESOURCES]: '3 - No CE Resources Available',
  [HPDelayCode.WRONG_CE]: '4 - Subcase Sent to wrong CE',
  [HPDelayCode.TRAVEL_DELAY]: '5 - Travel Delay',
  [HPDelayCode.NO_QUALIFIED_CE]: '6 - No Trained/Qualified CE Available',
  [HPDelayCode.SUBCASE_LATE]: '7 - Subcase recd late from Call Center',
  [HPDelayCode.CUSTOMER_CAUSED_DELAY]: '8 - Customer Caused Delay',
  [HPDelayCode.OTHER]: '9 - Other',
  [HPDelayCode.UNABLE_TO_REACH_CUSTOMER]: '10 - Unable to reach customer',
  [HPDelayCode.CANT_PROVIDE_TS_INFO]:
    '11 - Customer can’t provide TS information',
  [HPDelayCode.CUSTOMER_INACCURATE_DIAGNOSE]:
    '12 - Inaccurate diagnose by customer action',
  [HPDelayCode.PARTNER_INACCURATE_DIAGNOSE]:
    '13 - Inaccurate diagnose by partner action',
  [HPDelayCode.ACCESS_DENIED]: '14 - Access denied by customer',
};

/**
 * Options for the HP travel zone field
 */
export enum HPTravelZone {
  ZONE_0 = '00',
  ZONE_1 = '01',
  ZONE_2 = '02',
  ZONE_3 = '03',
  ZONE_4 = '04',
  ZONE_5 = '05',
  ZONE_6 = '06',
  ZONE_7 = '07',
  ZONE_8 = '08',
  ZONE_9 = '09',
  ZONE_N = '0N',
}

/**
 * Options for the HP usage code field
 */
export enum HPUsageCode {
  SAUN_UNUSED = 'SAUN-Unused',
  SAUS_USED_CONSUMED = 'SAUS-Used + Consumed',
  SAUS_USED_NOT_CONSUMED = 'SAUS-Used + Not Consumed',
  TRAN_TRANSFERRED = 'TRAN-Transferred',
  SAUN_UNUSED_DOA = 'SAUN-Unused (DOA)',
}

export const HP_USAGE_CODE_DISPLAY_NAME_MAP: {
  readonly [k in HPUsageCode]: string;
} = {
  [HPUsageCode.SAUN_UNUSED]: 'SAUN - Unused',
  [HPUsageCode.SAUS_USED_CONSUMED]: 'SAUS - Used + Consumed',
  [HPUsageCode.SAUS_USED_NOT_CONSUMED]: 'SAUS - Used + Not Consumed',
  [HPUsageCode.TRAN_TRANSFERRED]: 'TRAN - Transferred',
  [HPUsageCode.SAUN_UNUSED_DOA]: 'SAUN - Unused (DOA)',
};

/**
 * Options for the HP failure code field
 */
export enum HPFailureCode {
  DOA = '55 - DOA',
  RETURNED_GOOD = '72 - Returned good',
  USED_AND_CONSUMED = '73 - Used & Consumed',
}

export const HP_USAGE_FAILURE_CODE_MAPPING: {
  readonly [k in HPUsageCode]?: string;
} = {
  [HPUsageCode.SAUN_UNUSED]: HPFailureCode.RETURNED_GOOD,
  [HPUsageCode.SAUS_USED_CONSUMED]: HPFailureCode.USED_AND_CONSUMED,
  [HPUsageCode.SAUS_USED_NOT_CONSUMED]: HPFailureCode.DOA,
  [HPUsageCode.SAUN_UNUSED_DOA]: HPFailureCode.DOA,
  [HPUsageCode.TRAN_TRANSFERRED]: HPFailureCode.RETURNED_GOOD,
};

export const HP_FAILURE_CODE_OPTIONS: readonly SelectItem<
  '' | HPFailureCode
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: HPFailureCode.DOA,
    value: HPFailureCode.DOA,
  },
  {
    displayText: HPFailureCode.RETURNED_GOOD,
    value: HPFailureCode.RETURNED_GOOD,
  },
  {
    displayText: HPFailureCode.USED_AND_CONSUMED,
    value: HPFailureCode.USED_AND_CONSUMED,
  },
];

/**
 * Options for the HP additional failure code field
 */
export enum HPAdditionalFailureCode {
  BRANDING = 'Branding (mother board and formatters only)',
  PHYSICALLY_DAMAGED = 'PD - Physically Damaged',
  FAILED_WHEN_INSERTED = 'Functionality failed when inserted',
  PART_MISSING = 'PM - Part Missing',
  WRONG_PART_IN_BOX = 'WP - Wrong Part in Box',
  CUSTOMER_CANCELLED = 'Customer Cancelled',
  ORDERED_BY_CE_OPENED = 'Ordered by CE opened',
  ORDERED_BY_CE_UNOPENED = 'Ordered by CE unopened',
  RECOMMENDED_BY_DBD_OPENED = 'Recommended by DBD opened',
  RECOMMENDED_BY_DBD_UNOPENED = 'Recommended by DBD unopened',
  MISS_SHIPMENT = 'MS - Miss Shipment',
  PROACTIVE_REPLACEMENT = 'Proactive Replacement',
  REACTIVE_REPLACEMENT = 'Reactive Replacement',
  DAMAGED_OUT_OF_BOX = 'Damaged out of box',
}

export const HP_ADDITIONAL_FAILURE_CODE_OPTIONS: {
  readonly [k in HPUsageCode]?: readonly SelectItem<
    '' | HPAdditionalFailureCode
  >[];
} = {
  [HPUsageCode.SAUS_USED_NOT_CONSUMED]: [
    {
      displayText: '',
      value: '',
      disabled: true,
    },
    {
      displayText: HPAdditionalFailureCode.BRANDING,
      value: HPAdditionalFailureCode.BRANDING,
    },
    {
      displayText: HPAdditionalFailureCode.DAMAGED_OUT_OF_BOX,
      value: HPAdditionalFailureCode.DAMAGED_OUT_OF_BOX,
    },
    {
      displayText: HPAdditionalFailureCode.FAILED_WHEN_INSERTED,
      value: HPAdditionalFailureCode.FAILED_WHEN_INSERTED,
    },
    {
      displayText: HPAdditionalFailureCode.MISS_SHIPMENT,
      value: HPAdditionalFailureCode.MISS_SHIPMENT,
    },
    {
      displayText: HPAdditionalFailureCode.PHYSICALLY_DAMAGED,
      value: HPAdditionalFailureCode.PHYSICALLY_DAMAGED,
    },
    {
      displayText: HPAdditionalFailureCode.PART_MISSING,
      value: HPAdditionalFailureCode.PART_MISSING,
    },
    {
      displayText: HPAdditionalFailureCode.WRONG_PART_IN_BOX,
      value: HPAdditionalFailureCode.WRONG_PART_IN_BOX,
    },
  ],
  [HPUsageCode.SAUN_UNUSED]: [
    {
      displayText: '',
      value: '',
      disabled: true,
    },
    {
      displayText: HPAdditionalFailureCode.CUSTOMER_CANCELLED,
      value: HPAdditionalFailureCode.CUSTOMER_CANCELLED,
    },
    {
      displayText: HPAdditionalFailureCode.ORDERED_BY_CE_OPENED,
      value: HPAdditionalFailureCode.ORDERED_BY_CE_OPENED,
    },
    {
      displayText: HPAdditionalFailureCode.ORDERED_BY_CE_UNOPENED,
      value: HPAdditionalFailureCode.ORDERED_BY_CE_UNOPENED,
    },
    {
      displayText: HPAdditionalFailureCode.RECOMMENDED_BY_DBD_OPENED,
      value: HPAdditionalFailureCode.RECOMMENDED_BY_DBD_OPENED,
    },
    {
      displayText: HPAdditionalFailureCode.RECOMMENDED_BY_DBD_UNOPENED,
      value: HPAdditionalFailureCode.RECOMMENDED_BY_DBD_UNOPENED,
    },
  ],
  [HPUsageCode.SAUN_UNUSED_DOA]: [
    {
      displayText: '',
      value: '',
      disabled: true,
    },
    {
      displayText: HPAdditionalFailureCode.CUSTOMER_CANCELLED,
      value: HPAdditionalFailureCode.CUSTOMER_CANCELLED,
    },
    {
      displayText: HPAdditionalFailureCode.ORDERED_BY_CE_OPENED,
      value: HPAdditionalFailureCode.ORDERED_BY_CE_OPENED,
    },
    {
      displayText: HPAdditionalFailureCode.ORDERED_BY_CE_UNOPENED,
      value: HPAdditionalFailureCode.ORDERED_BY_CE_UNOPENED,
    },
    {
      displayText: HPAdditionalFailureCode.RECOMMENDED_BY_DBD_OPENED,
      value: HPAdditionalFailureCode.RECOMMENDED_BY_DBD_OPENED,
    },
    {
      displayText: HPAdditionalFailureCode.RECOMMENDED_BY_DBD_UNOPENED,
      value: HPAdditionalFailureCode.RECOMMENDED_BY_DBD_UNOPENED,
    },
  ],
  [HPUsageCode.SAUS_USED_CONSUMED]: [
    {
      displayText: '',
      value: '',
      disabled: true,
    },
    {
      displayText: HPAdditionalFailureCode.PROACTIVE_REPLACEMENT,
      value: HPAdditionalFailureCode.PROACTIVE_REPLACEMENT,
    },
    {
      displayText: HPAdditionalFailureCode.REACTIVE_REPLACEMENT,
      value: HPAdditionalFailureCode.REACTIVE_REPLACEMENT,
    },
  ],
};

/**
 * Options for Yes/No flag fields
 */
export enum FlagField {
  YES = 'Yes',
  NO = 'No',
}

/**
 * Return override flag custom field is mapped as follows:
 * - Parts being returned -> flag false
 * - Parts not being returned -> flag true
 */
export const RETURN_OVERRIDE_FLAG_FIELD_OPTIONS: readonly SelectItem<
  '' | boolean
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: FlagField.YES,
    value: false,
  },
  {
    displayText: FlagField.NO,
    value: true,
  },
];

/**
 * Mapping between usage code options that require the return parts input
 */
export const HP_USAGE_CODE_RETURN_PARTS_MAPPING: {
  readonly [k in HPUsageCode]?: boolean;
} = {
  [HPUsageCode.SAUN_UNUSED]: false,
  [HPUsageCode.SAUS_USED_CONSUMED]: true,
  [HPUsageCode.SAUS_USED_NOT_CONSUMED]: false,
};

/**
 * Options for the HP return override reason field
 */
export enum HPReturnOverrideReason {
  DMR_ENTITLEMENT_COVERAGE = 'DMR Entitlement Coverage',
  SPECIAL_HP_APPROVAL = 'Special HP Approval',
  CUSTOMER_DEFECTIVE_BUY_BACK = 'Customer Defective Buy Back',
}

export const HP_RETURN_OVERRIDE_REASON_OPTIONS: readonly SelectItem<
  '' | HPReturnOverrideReason
>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: HPReturnOverrideReason.DMR_ENTITLEMENT_COVERAGE,
    value: HPReturnOverrideReason.DMR_ENTITLEMENT_COVERAGE,
  },
  {
    displayText: HPReturnOverrideReason.SPECIAL_HP_APPROVAL,
    value: HPReturnOverrideReason.SPECIAL_HP_APPROVAL,
  },
  {
    displayText: HPReturnOverrideReason.CUSTOMER_DEFECTIVE_BUY_BACK,
    value: HPReturnOverrideReason.CUSTOMER_DEFECTIVE_BUY_BACK,
  },
];

/**
 * Options for the HP labor duration field
 */
export const HP_LABOR_DURATION_OPTIONS: readonly SelectItem<'' | number>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: '30 Minutes',
    value: 30,
  },
  {
    displayText: '60 Minutes',
    value: 60,
  },
  {
    displayText: '90 Minutes',
    value: 90,
  },
  {
    displayText: '120 Minutes',
    value: 120,
  },
  {
    displayText: '150 Minutes',
    value: 150,
  },
  {
    displayText: '180 Minutes',
    value: 180,
  },
  {
    displayText: '210 Minutes',
    value: 210,
  },
  {
    displayText: '240 Minutes',
    value: 240,
  },
];

/**
 * Options for the HP travel duration field
 */
export const HP_TRAVEL_DURATION_OPTIONS: readonly SelectItem<'' | number>[] = [
  {
    displayText: '',
    value: '',
    disabled: true,
  },
  {
    displayText: '30 Minutes',
    value: 30,
  },
  {
    displayText: '60 Minutes',
    value: 60,
  },
  {
    displayText: '90 Minutes',
    value: 90,
  },
  {
    displayText: '120 Minutes',
    value: 120,
  },
  {
    displayText: '150 Minutes',
    value: 150,
  },
  {
    displayText: '180 Minutes',
    value: 180,
  },
  {
    displayText: '210 Minutes',
    value: 210,
  },
  {
    displayText: '240 Minutes',
    value: 240,
  },
];

export const HP_TASK_SECTION_FILE_NAME: {
  readonly [k in HPTaskSectionName]?: string;
} = {
  [HPTaskSectionName.PARTS_RECEIVED]: 'confirm-parts',
  [HPTaskSectionName.ONSITE]: 'pre-repair',
  [HPTaskSectionName.ONSITE_POST_REPAIR_PHOTO]: 'post-repair',
  [HPTaskSectionName.PARTS_RETURN]: 'parts-return',
};

export enum FIELD_SERVICES_TRACKER_CUSTOM_COLUMN {
  STATUS = 'Status',
}
